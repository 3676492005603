import downloadBlob from '@/domains/common/services/utils/downloadFileHelper';
import {
    WebGLRenderer,
    PerspectiveCamera,
    type WebGLRendererParameters,
    type DataTexture,
    OrthographicCamera,
    Camera,
    PMREMGenerator,
} from 'three';
import type World from '@/3d-app/commons/world/World';

/**
 * Object used for scene screenshots.
 */

const Screenshot = {
    /**
     * Download given screenshot as an image file. Extension will match screenshot type.
     * @param screenshot blob containing the image to download
     * @param namePrefix name of the file before date (if used) and extension. Ex: "myImage_".
     * @param useDateTime wether the image will have a date time as suffix.
     *  Ex with "myImage_" prefix, and a png image, you will get "myImage_YYYY-MM-DD_HHh-MMm-SSs.png"
     */
    downloadScreenshot(screenshot: Blob, namePrefix: string, useDateTime: boolean = true): void {
        const extension = `.${screenshot.type.split('/').pop()}`;
        let nameSuffix = '';
        if (useDateTime) {
            // Filename will look like [namePrefix]YYYY-MM-DD_HHh-MMm-SSs.[extension]
            const now = new Date();
            const date = now.toISOString().split('T')[0];
            const time = `${now.getHours()}h-${now.getMinutes()}m-${now.getSeconds()}s`;
            nameSuffix = `${date}_${time}`;
        }

        downloadBlob(screenshot, `${namePrefix}${nameSuffix}${extension}`);
    },

    /**
     * Do a screenshot of the scene
     * @param width width of the captured image, in pixels.
     *  WARNING! Small width will crop the image (reference length is height)
     * @param height height of the captured image, in pixels
     * @param world the world to render
     * @param camera the camera used for the capture, can be othographic or perspective
     * @param rendererParameters parameters used for generating current renderer (to create a similar renderer)
     * @param hdriData data texture of the scene hdri (if there is)
     * @param isBackTransparent true for a transparent background, false to use current scene background
     * @param type type of the returned image
     * @param quality quality of the returned image
     * @returns a promise that is resolved when the screenshot is captured, with the image as a Blob
     */
    screenshot(
        width: number,
        height: number,
        world: World,
        camera: Camera,
        rendererParameters: WebGLRendererParameters,
        hdriData: DataTexture | null = null,
        isBackTransparent: boolean = true,
        type: string = 'image/png',
        quality: number = 1.0,
    ): Promise<Blob> {
        const wasEnabled = world.mainLoop.enabled;
        if (wasEnabled) {
            world.mainLoop.stop(); // Freeze main render
        }

        const parameters = { ...rendererParameters };

        const offCanvas = document.createElement('canvas'); // Create offscreen canvas
        parameters.canvas = offCanvas; // Target new canvas
        parameters.antialias = true; // Add antialiasing

        // Create the renderer (clone of the original with different target canvas)
        const renderer = new WebGLRenderer(parameters);

        const originalBackground = world.scene.background; // Save background
        if (isBackTransparent) {
            // Set background to transparent
            renderer.setClearAlpha(0);
            world.scene.background = null;
        }
        const originalEnvironment = world.scene.environment; // Save original environment

        renderer.setSize(width, height); // Adapt renderer size to specified dimensions

        // Setup hdri (need to reload it cause it's associated with renderer)
        if (hdriData) {
            world.scene.environment = new PMREMGenerator(renderer).fromEquirectangular(hdriData).texture;
        }

        const aspect = width / height;
        if (camera instanceof PerspectiveCamera) {
            const perspectiveCamera = camera as PerspectiveCamera;
            const originalAspect = perspectiveCamera.aspect;
            perspectiveCamera.aspect = aspect; // Adapt camera ratio to specified dimensions
            perspectiveCamera.updateProjectionMatrix();

            renderer.render(world.scene, perspectiveCamera); // Render the scene

            perspectiveCamera.aspect = originalAspect; // Restore aspect
            perspectiveCamera.updateProjectionMatrix();
        } else if (camera instanceof OrthographicCamera) {
            const orthographicCamera = camera as OrthographicCamera;
            orthographicCamera.left *= aspect;
            orthographicCamera.right *= aspect;
            orthographicCamera.updateProjectionMatrix();

            renderer.render(world.scene, orthographicCamera); // Render the scene

            orthographicCamera.left /= aspect; // Restore left
            orthographicCamera.right /= aspect; // Restore right
            orthographicCamera.updateProjectionMatrix();
        }

        // Save the render to a blob
        return new Promise((resolve, reject) => {
            renderer.domElement.toBlob(
                (blob) => {
                    world.scene.background = originalBackground; // Restore background
                    world.scene.environment = originalEnvironment; // Restore hdri
                    renderer.dispose(); // Remove screenshot renderer
                    renderer.forceContextLoss();
                    if (wasEnabled) {
                        world.mainLoop.start(); // Unfreeze main render
                    }

                    if (blob) {
                        resolve(blob);
                    } else {
                        reject(new Error('Screenshot blob is empty'));
                    }
                },
                type,
                quality,
            );
        });
    },
};

export default Screenshot;
