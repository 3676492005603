const PasswordStrength = {
    STRENGTH_VERY_WEAK: 0,
    STRENGTH_WEAK: 1,
    STRENGTH_MEDIUM: 2,
    STRENGTH_STRONG: 3,
    STRENGTH_VERY_STRONG: 4,
};

function estimateStrength(password: string): number {
    if (!password.length) {
        return PasswordStrength.STRENGTH_VERY_WEAK;
    }

    const passwordChars = new Map<number, number>();
    for (let i = 0; i < password.length; i++) {
        const charCode = password.charCodeAt(i);
        passwordChars.set(charCode, (passwordChars.get(charCode) ?? 0) + 1);
    }

    const chars = passwordChars.size;
    let control = 0;
    let digit = 0;
    let upper = 0;
    let lower = 0;
    let symbol = 0;
    let other = 0;

    passwordChars.forEach((count, chr) => {
        if (chr < 32 || chr === 127) {
            control = 33;
        } else if (chr >= 48 && chr <= 57) {
            digit = 10;
        } else if (chr >= 65 && chr <= 90) {
            upper = 26;
        } else if (chr >= 97 && chr <= 122) {
            lower = 26;
        } else if (chr >= 128) {
            other = 128;
        } else {
            symbol = 33;
        }
    });

    const pool = lower + upper + digit + symbol + control + other;
    const entropy = chars * Math.log2(pool) + (password.length - chars) * Math.log2(chars);

    if (entropy >= 120) {
        return PasswordStrength.STRENGTH_VERY_STRONG;
    }
    if (entropy >= 100) {
        return PasswordStrength.STRENGTH_STRONG;
    }
    if (entropy >= 80) {
        return PasswordStrength.STRENGTH_MEDIUM;
    }
    if (entropy >= 60) {
        return PasswordStrength.STRENGTH_WEAK;
    }
    return PasswordStrength.STRENGTH_VERY_WEAK;
}

function getPasswordStrengthClass(passwordStrength: number) {
    switch (passwordStrength) {
        case PasswordStrength.STRENGTH_VERY_WEAK:
            return 'bg-red-500 w-1/5';
        case PasswordStrength.STRENGTH_WEAK:
            return 'bg-orange-500 w-2/5';
        case PasswordStrength.STRENGTH_MEDIUM:
            return 'bg-yellow-500 w-3/5';
        case PasswordStrength.STRENGTH_STRONG:
            return 'bg-green-300 w-4/5';
        case PasswordStrength.STRENGTH_VERY_STRONG:
            return 'bg-green-500 w-full';
        default:
            return 'bg-red-500 w-4';
    }
}

export { estimateStrength, getPasswordStrengthClass };
