<script setup lang="ts">
import router from '@/router';
import OFFSET_Y_VALUE_SCROLL_HEADER from '@/domains/common/services/header.config';
import BaseButton from '@/domains/common/components/BaseButton.vue';
import BaseButtonRouterLink from '@/domains/common/components/BaseButtonRouterLink.vue';
import BaseLink from '@/domains/common/components/BaseLink.vue';
import ButtonColorEnum from '@/domains/common/typescript/ButtonColorEnum';
import IconArrowTopRightSquare from '@/domains/common/components/icons/IconArrowTopRightSquare.vue';
import MenuDropdown from '@/domains/common/components/MenuDropdown.vue';
import TheSelectOfficeHeader from '@/domains/authentication/components/TheSelectOfficeHeader.vue';
import { logout } from '@/domains/authentication/services/authenticate';
import RouteNameEnum from '@/router/routeNameEnum';
import useAuthStore from '@/domains/authentication/store/auth.store';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

const allAdminMenuItems: { title: string; name: RouteNameEnum }[] = [
    {
        title: 'menu.office',
        name: RouteNameEnum.AdminOffice,
    },
    {
        title: 'menu.user',
        name: RouteNameEnum.AdminUser,
    },
    {
        title: 'menu.announcement',
        name: RouteNameEnum.AdminAnnouncement,
    },
];

const allMyOfficeMenuItems: { title: string; name: RouteNameEnum }[] = [
    {
        title: 'menu.users',
        name: RouteNameEnum.MyOfficeUser,
    },
    {
        title: 'menu.infos',
        name: RouteNameEnum.MyOfficeEdit,
    },
];

const route = useRoute();
const authStore = useAuthStore();

const { isUserAuthorized } = authStore;

const isMenuExpanded = ref<Boolean>(true);

const isMenuLarge = computed(() => isMenuExpanded.value && route.name !== RouteNameEnum.Scan);

const isMenuDisabled = computed(() => route.name === RouteNameEnum.SelectOffices);

const handleScroll = () => {
    isMenuExpanded.value = window.scrollY < OFFSET_Y_VALUE_SCROLL_HEADER;
};

onMounted(() => {
    window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll);
});

const elementsAdminSubmenu = computed(() => {
    return filterAllowedMenuItems(allAdminMenuItems);
});

const elementsMyOfficeSubmenu = computed(() => {
    return filterAllowedMenuItems(allMyOfficeMenuItems);
});

function filterAllowedMenuItems(items: { title: string; name: RouteNameEnum }[]) {
    const allowedMenuItems = items.filter((item) => {
        const routeResult = router.getRoutes().find((r) => r.name === item.name);

        if (!routeResult) {
            return false;
        }

        return isUserAuthorized((routeResult.meta.roles as any[]) || []);
    });

    return allowedMenuItems;
}

function isSubmenuActive(basePath: string) {
    return router.currentRoute.value.path.startsWith(basePath);
}
</script>

<template>
    <header
        :class="[
            'fixed top-0 z-20 w-full rounded-bl-full rounded-br-full bg-white shadow-drop shadow-shadow transition-all duration-300 ease-in-out',
            isMenuLarge ? 'h-32' : 'h-12',
        ]"
    >
        <div class="m-auto flex h-full max-w-screen-2xl items-center px-8">
            <img
                src="@/assets/images/logo-couleur.png"
                alt="Logo de la marque Podofit"
                :class="['h-full max-h-16 cursor-pointer', isMenuDisabled && 'pointer-events-none']"
                @click="router.push({ path: '/' })"
            />
            <div class="flex h-full w-full flex-grow items-center justify-between">
                <nav class="ml-20 flex">
                    <BaseButtonRouterLink
                        :to="{ name: RouteNameEnum.Patients }"
                        :class="[
                            'transition-colors duration-300 ease-in-out',
                            isMenuDisabled && 'pointer-events-none opacity-50',
                        ]"
                        active-class="!text-primary !font-bold"
                        :color="ButtonColorEnum.Menu"
                        :label="$t('menu.patients')"
                    />
                    <BaseButtonRouterLink
                        :to="{ name: RouteNameEnum.Orders }"
                        :class="[
                            'px-4 font-semibold transition-colors duration-300 ease-in-out',
                            isMenuDisabled && 'pointer-events-none opacity-50',
                        ]"
                        active-class="!text-primary !font-bold"
                        :color="ButtonColorEnum.Menu"
                        :label="$t('menu.orders')"
                    />
                    <BaseButtonRouterLink
                        :to="{ name: RouteNameEnum.Settings }"
                        :class="[
                            'px-4 font-semibold transition-colors duration-300 ease-in-out',
                            isMenuDisabled && 'pointer-events-none opacity-50',
                        ]"
                        active-class="!text-primary !font-bold"
                        :color="ButtonColorEnum.Menu"
                        :label="$t('menu.parameters')"
                    />
                </nav>
                <div class="relative flex h-full items-center justify-center">
                    <div :class="[isMenuLarge ? 'pr-0' : 'pr-14', 'flex items-center']">
                        <MenuDropdown :class="[isMenuDisabled && 'pointer-events-none opacity-50']">
                            <template #buttonBody>
                                <span>{{ $t('menu.my_account') }}</span>
                            </template>
                            <template #dropdownList>
                                <BaseButton
                                    :label="$t('menu.logout')"
                                    :color="ButtonColorEnum.Menu"
                                    @click="logout({ shouldLoginRedirect: true })"
                                />
                            </template>
                        </MenuDropdown>

                        <MenuDropdown
                            v-if="elementsAdminSubmenu.length"
                            :class="[isMenuDisabled && 'pointer-events-none opacity-50']"
                        >
                            <template #buttonBody>
                                <span :class="isSubmenuActive('/admin') && 'font-bold text-primary'">
                                    {{ $t('menu.administration') }}
                                </span>
                            </template>
                            <template #dropdownList>
                                <BaseButtonRouterLink
                                    v-for="el in elementsAdminSubmenu"
                                    :key="el.name"
                                    :to="{ name: el.name }"
                                    class="px-4 py-2"
                                    :color="ButtonColorEnum.Menu"
                                    active-class="!text-primary !font-bold"
                                    :label="$t(el.title)"
                                />
                            </template>
                        </MenuDropdown>

                        <MenuDropdown
                            v-if="elementsMyOfficeSubmenu.length"
                            :class="[isMenuDisabled && 'pointer-events-none opacity-50']"
                        >
                            <template #buttonBody>
                                <span :class="isSubmenuActive('/my-office') && 'font-bold text-primary'">
                                    {{ $t('menu.my_office') }}
                                </span>
                            </template>
                            <template #dropdownList>
                                <BaseButtonRouterLink
                                    v-for="el in elementsMyOfficeSubmenu"
                                    :key="el.name"
                                    :to="{ name: el.name }"
                                    class="px-4 py-2"
                                    :color="ButtonColorEnum.Menu"
                                    active-class="!text-primary !font-bold"
                                    :label="$t(el.title)"
                                />
                            </template>
                        </MenuDropdown>
                        <BaseLink
                            link="https://www.ergorecherche.fr/aide_podofit/"
                            :class="[
                                'px-4 font-semibold transition-colors duration-300 ease-in-out hover:text-secondary',
                                isMenuDisabled && 'pointer-events-none opacity-50',
                            ]"
                            :color="ButtonColorEnum.Menu"
                            :label="$t('menu.help')"
                            :icon="IconArrowTopRightSquare"
                        />
                    </div>
                    <TheSelectOfficeHeader :expanded-menu="isMenuLarge" />
                </div>
            </div>
        </div>
    </header>
</template>
