import ScanController from './ScanController';

/**
 * Manager of import and export. Used for data persistance.
 */
const ImportExportManager = {
    export(): Blob {
        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }
        const dataToExport = {
            soles: {
                left: scanController.getSoleMesh(false).toJSON(), // Assuming you have references to your sole meshes
                right: scanController.getSoleMesh(true).toJSON(),
            },
            scans: {
                left: scanController.getScanMesh(false).toJSON(),
                right: scanController.getScanMesh(true).toJSON(),
            },
        };

        // Convert the data to a JSON string
        const jsonExport = JSON.stringify(dataToExport);

        // Create a Blob with the JSON data
        const blob = new Blob([jsonExport], { type: 'application/json' });

        return blob;
    },

    import(importedFile: File): Promise<void> {
        const scanController = ScanController.instance;
        if (!scanController) {
            return Promise.reject(new Error('ScanController not ready!'));
        }

        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                const fileContent = event.target?.result as string;

                try {
                    const jsonData = JSON.parse(fileContent);

                    Promise.all([
                        scanController.getScanMesh(false).fromJSON(jsonData.scans.left, false),
                        scanController.getScanMesh(true).fromJSON(jsonData.scans.right, true),
                        scanController.getSoleMesh(false).fromJSON(jsonData.soles.left),
                        scanController.getSoleMesh(true).fromJSON(jsonData.soles.right),
                    ]).then((meshes) => {
                        meshes.forEach((mesh) => {
                            if (mesh) {
                                scanController.scene.add(mesh);
                            }
                        });

                        scanController.updateSelectedFoot();
                        resolve();
                    });
                } catch (error) {
                    // Handle the error as needed
                    console.error('Error parsing JSON:', error);
                    reject(error);
                }
            };

            reader.readAsText(importedFile);
        });
    },
};

export default ImportExportManager;
