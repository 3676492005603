import type ModeEnum from '../scan/ModeEnum';

/**
 * Parent class of all modes.
 * A mode, when activated, will change the application behaviour for a specific use.
 * Only one mode (or none) can be activated at a time.
 * A mode contains tool(s) for the specific use logic.
 * The common user interactions are managed in the mode.
 */
class Mode {
    private _enabled: boolean = false;
    private _name: ModeEnum;

    constructor(name: ModeEnum) {
        this._name = name;
    }

    get enabled(): boolean {
        return this._enabled;
    }

    set enabled(isEnabled: boolean) {
        this._enabled = isEnabled;
    }

    get name(): ModeEnum {
        return this._name;
    }

    public activate(): void {
        this.enabled = true;
    }

    public deactivate(): void {
        this.enabled = false;
    }

    /* eslint-disable class-methods-use-this */
    public dispose(): void {}
}

export default Mode;
