<script setup lang="ts">
import timer from '@/domains/common/services/utils/timer';
import { TransitionRoot } from '@headlessui/vue';
import { ref, watchEffect, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';

const controlButton = ref<HTMLElement | null>(null);
const menu = ref<HTMLElement | null>(null);
const displaySubmenu = ref<boolean>(false);
const isMouseOverSubmenu = ref<boolean>(false);

const offsetLeft = ref(0);

onClickOutside(menu, onClickOutsideListener);

const props = withDefaults(defineProps<{ stickRight?: boolean; clickable?: boolean; compact?: boolean }>(), {
    stickRight: false,
    clickable: false,
    compact: false,
});

watchEffect(() => {
    if (controlButton.value) {
        offsetLeft.value = controlButton.value.offsetLeft;
    }
});

watch(isMouseOverSubmenu, async () => {
    if (props.clickable) {
        return;
    }
    await timer(300);
    if (!isMouseOverSubmenu.value) {
        displaySubmenu.value = false;
    }
});

function onMouseOver() {
    if (!props.clickable) {
        displaySubmenu.value = true;
        isMouseOverSubmenu.value = true;
    }
}

function onMouseOut() {
    if (!props.clickable) {
        isMouseOverSubmenu.value = false;
    }
}

function onClickOutsideListener() {
    if (props.clickable) {
        displaySubmenu.value = false;
    }
}

function onClick() {
    if (props.clickable) {
        displaySubmenu.value = !displaySubmenu.value;
    }
}
</script>

<template>
    <div ref="menu" class="relative">
        <button
            ref="controlButton"
            :class="[
                'flex h-full items-center py-1 font-semibold transition-colors duration-300 ease-in-out hover:text-secondary',
                !compact && 'px-4',
            ]"
            @mouseover="onMouseOver()"
            @mouseout="onMouseOut()"
            @click="onClick()"
        >
            <slot name="buttonBody"></slot>
        </button>
        <TransitionRoot
            :show="displaySubmenu"
            enter="transform transition duration-[400ms]"
            enter-from="opacity-0 -translate-y-24"
            enter-to="opacity-100 translate-y-0"
            leave="transform transition duration-[300ms]"
            leave-from="opacity-100 translate-y-0"
            leave-to="opacity-0 -translate-y-24"
            class="absolute top-16 z-10 flex w-max flex-col items-center rounded-xl bg-white px-5 py-4 font-semibold shadow-shadow drop-shadow-around-md"
            :style="stickRight ? `right:${offsetLeft}px` : `left:${offsetLeft}px`"
            @mouseover="onMouseOver()"
            @mouseout="onMouseOut()"
        >
            <slot name="dropdownList"></slot>
        </TransitionRoot>
    </div>
</template>
