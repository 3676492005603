import ScanController from '@/3d-app/scan/ScanController';
import MeshUtils from '@/3d-app/commons/MeshUtils';
import Tool from '@/3d-app/commons/Tool';
import { Quaternion, Raycaster, Vector2, Vector3 } from 'three';

class AlignScanTool extends Tool {
    private _capturedMouseButton: number;

    private _originPosition: Vector3 = new Vector3();

    private _originRotation: Quaternion = new Quaternion();

    private _originMouse3D: Vector3 = new Vector3();

    private _raycaster: Raycaster;

    get isInTransform(): boolean {
        return this._capturedMouseButton !== -1;
    }

    public constructor() {
        super();

        this._capturedMouseButton = -1;
        this._raycaster = new Raycaster();
    }

    private isScanUnderMouse(mouseX: number, mouseY: number): boolean {
        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }
        this._raycaster.setFromCamera(
            new Vector2(
                (mouseX / scanController.containerSize.width) * 2 - 1,
                -(mouseY / scanController.containerSize.height) * 2 + 1,
            ),
            scanController.camera,
        );

        const hit = this._raycaster.intersectObject(scanController.currentScanMesh.scanGeometry); // Check only current scan

        return hit.length > 0;
    }

    public mouseDownLogic(mouseButton: number, mouseX: number, mouseY: number): void {
        if ((mouseButton === 0 || mouseButton === 2) && this._capturedMouseButton === -1) {
            if (this.isScanUnderMouse(mouseX, mouseY)) {
                const scanController = ScanController.instance;
                if (!scanController) {
                    throw new Error('ScanController not ready!');
                }
                // Left or right buttons only
                this._capturedMouseButton = mouseButton;
                // Save origin 3D mouse position
                this._originMouse3D = MeshUtils.mouseToWorld(
                    mouseX,
                    mouseY,
                    scanController.containerSize,
                    scanController.camera,
                );
                // Save transformations
                const tmpVec = new Vector3();
                scanController.currentScanMesh.mesh.matrix.decompose(
                    this._originPosition,
                    this._originRotation,
                    tmpVec,
                );
            }
        }
    }

    public mouseUpLogic(mouseButton: number): void {
        if (mouseButton !== this._capturedMouseButton) {
            return;
        }
        this._capturedMouseButton = -1;
    }

    public mouseMoveLogic(mouseX: number, mouseY: number): void {
        if (this._capturedMouseButton === -1) {
            return;
        }

        const scanController = ScanController.instance;
        if (!scanController) {
            throw new Error('ScanController not ready!');
        }

        const mouse3DPos = MeshUtils.mouseToWorld(mouseX, mouseY, scanController.containerSize, scanController.camera);

        if (this._capturedMouseButton === 0) {
            // Left button down
            // Translation movement
            const meshPos = scanController.currentScanMesh.mesh.position;
            meshPos.copy(this._originPosition.clone().add(mouse3DPos.sub(this._originMouse3D)));
            // Limit the movement, to avoid putting scan on wrong sole or loosing it in the distance
            const offset = 30 * ScanController.SCALE_TO_M_FACTOR;
            const limit = 300 * ScanController.SCALE_TO_M_FACTOR;
            meshPos.y = Math.min(Math.max(meshPos.y, -limit), limit);
            if (scanController.isRightFoot) {
                meshPos.x = Math.min(Math.max(meshPos.x, offset), limit);
            } else {
                meshPos.x = Math.min(Math.max(meshPos.x, -limit), -offset);
            }
        } else {
            // Right button down
            // Rotation movement
            scanController.currentScanMesh.mesh.quaternion.copy(this._originRotation);
            const wp = new Vector3();
            scanController.currentScanMesh.mesh.getWorldPosition(wp);

            // Get signed angle between the two vectors by normal plane
            const vA = this._originMouse3D.clone().sub(wp).normalize();
            const vB = mouse3DPos.sub(wp).normalize();
            let angle = Math.acos(vA.dot(vB));
            const cross = vA.cross(vB);
            const normal = new Vector3(0, 0, 1);
            if (normal.dot(cross) < 0) {
                angle = -angle;
            }
            scanController.currentScanMesh.mesh.rotateOnWorldAxis(new Vector3(0, 0, 1), angle);
        }
    }
}

export default AlignScanTool;
