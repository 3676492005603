import ManufactureTypeEnum from '@/domains/scan/typescript/enums/ManufactureTypeEnum';
import type SettingsInterface from '@/domains/settings/typescript/interfaces/SettingsInterface';
import type SettingsDTOInterface from '@/domains/settings/typescript/interfaces/SettingsDTOInterface';

export default function transformSettingstoDTO(settings: SettingsInterface): SettingsDTOInterface {
    return {
        id: settings.id,
        comment: settings.comment,
        sanded: settings.isSanded,
        glued: settings.isGlued,
        padProvided: settings.padProvided,
        viewSynchronized: settings.viewSynchronized,
        manufacture: settings.manufacture === ManufactureTypeEnum.Milling ? 0 : 1,
        owner: settings.userId,
    } as SettingsDTOInterface;
}
