import type ParamsOutputInterface from '@/domains/scan/typescript/interfaces/ParamsOutputInterface';

const selectParamsCorrectionAdapter = (options: Record<string, ParamsOutputInterface>) => ({
    SoleThickness: options.EpaisseurSemelle,
    ThickerFrontFoot: options.AffinerAvantPied,
    GapFirstHead: options.Retrait1erMetatarse,
    GapFifthHead: options.Retrait5emeMetatarse,
    InterArchPoint: options.HauteurInterarche,
    InterArchPointConcavity: options.ForceInterarche,
    InternalArch: options.HauteurArcheMediale,
    InternalArchSummitsPosition: options.PositionArcheMediale,
    ExternalArch: options.HauteurArcheLaterale,
    ExternalArchSummitsPosition: options.PositionArcheLaterale,
    AddedThicknessHeel: options.ProfondeurTalon,
    MedialCupolaHeight: options.HauteurCoupoleMediale,
    LateralCupolaHeight: options.HauteurCoupoleLateral,
    FlatnessHeel: options.AplanirTalon,
    HeelWidening: options.AgrandirTalon,
    MidfootWidening: options.AgrandirMediopied,
    MetatarsalWidening: options.AgrandirMetatarse,
    TotalPatternWidening: options.AgrandirGabarit,
    FrontBevelHeight: options.HauteurBiseauAvant,
    HeelBevelHeight: options.HauteurBiseauTalon,
    FullLengthBevelHeight: options.HauteurBiseauTotal,
});

export default selectParamsCorrectionAdapter;
