import type { Vector3 } from 'three';

export enum ControlsOperation {
    PAN = 'PAN',
    ROTATE = 'ROTATE',
    ZOOM = 'ZOOM',
}

export type TControlMouse = 0 | 1 | 2 | 'WHEEL';

export enum ControlKey {
    SHIFT = 'SHIFT',
    CTRL = 'CTRL',
}

interface ICameraControls {
    update(): unknown;
    enableRotate: boolean;
    enablePan: boolean;
    enableZoom: boolean;
    enableDamping: boolean;
    dampingFactor: number;
    target: Vector3;
    addEventListener(arg0: string, arg1: () => void): unknown;
    rotateSpeed: number;
    zoomSpeed: number;
    panSpeed: number;
    maxDistance: number;
    minDistance: number;
    addMouseAction(operation: ControlsOperation, mouse: TControlMouse, key?: ControlKey): void;
    removeMouseAction(mouse: TControlMouse, key?: ControlKey): boolean;
    dispose(): void;
}

export default ICameraControls;
