import { TextureLoader } from 'three';

import type { Texture } from 'three';
import type { ILoadedPicture } from './ILoadedPicture';

class PictureLoader {
    private static _instance: PictureLoader; // eslint-disable-line

    private _loaderUtils = {
        threeTextureLoader: new TextureLoader(),
    };

    private _pictures: [ILoadedPicture | null] = [null];

    private static get getInstance(): PictureLoader {
        if (!PictureLoader._instance) {
            PictureLoader._instance = new PictureLoader();
        }

        return PictureLoader._instance;
    }

    public static loadPicture(picturePath: string): Promise<Texture> {
        return PictureLoader.getInstance._loadPicture(picturePath);
    }

    public static getNameFromPath(path: string) {
        const name = path?.split('/').pop();
        if (!name) {
            return '';
        }
        return name.split('.')[0];
    }

    public static isLoadedPicture(name: string): boolean {
        return PictureLoader.getInstance._pictures.find((loadedPicture) => loadedPicture?.name === name) != null;
    }

    private _loadPicture(picturePath: string, id: string | null = null): Promise<Texture> {
        const name = id ?? PictureLoader.getNameFromPath(picturePath);
        const isPictureLoaded = this._pictures.find((loadedPicture) => loadedPicture?.name === name);
        if (isPictureLoaded) {
            return isPictureLoaded.promise;
        }
        const loadedPicture = {
            name,
        } as ILoadedPicture;
        loadedPicture.promise = new Promise((resolve, reject) => {
            this._loaderUtils.threeTextureLoader.load(
                picturePath,
                (texture: Texture) => {
                    loadedPicture.texture = texture;
                    resolve(loadedPicture.texture);
                },
                // onProgress is not supported at current time
                undefined,
                (errorMsg: ErrorEvent) => {
                    reject(errorMsg);
                },
            );
        });
        this._pictures.push(loadedPicture);

        return loadedPicture.promise;
    }

    public static init() {
        return PictureLoader.getInstance;
    }
}

export default PictureLoader;
