import { LoadExternalLibrary, SetExternalLibLocation } from './ExternalLibs';
import { Rhino3dmLoader } from 'three/examples/jsm/loaders/3DMLoader';

let _rhinoLoader: Rhino3dmLoader | null = null;

/**
 * Get rhino loader
 * @returns the rhino loader configured
 */
export function getRhinoLoader(): Rhino3dmLoader {
    if (!_rhinoLoader) {
        _rhinoLoader = new Rhino3dmLoader();
        _rhinoLoader.setLibraryPath('https://cdn.jsdelivr.net/npm/rhino3dm@8.0.1/');
    }
    return _rhinoLoader;
}

let _rhino: Promise<any> | null = null;
declare function rhino3dm(): any;

/**
 * Returns the rhino3dm utility (as a promise! always use it in the .then())
 * @returns Promise containing the rhino3dm utility
 */
export function getRhino(): Promise<any> {
    if (!_rhino) {
        SetExternalLibLocation('https://cdn.jsdelivr.net/npm/rhino3dm@8.0.1');
        _rhino = LoadExternalLibrary('rhino3dm.min.js').then(() => {
            return rhino3dm().catch(console.error);
        });
    }
    return _rhino;
}
