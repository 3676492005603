<script setup lang="ts">
import ColorEnum from '@/domains/common/typescript/ColorEnum';
import BaseButton from '@/domains/common/components/BaseButton.vue';
import IconCross from '@/domains/common/components/icons/IconCross.vue';
import { Field } from 'vee-validate';
import { ref, type Ref } from 'vue';

defineOptions({
    inheritAttrs: false,
});

export interface Props {
    id: string;
    icon?: Object | null;
    iconSide?: string;
    type: string;
    placeholder?: string;
    color?: ColorEnum;
    name?: string;
    modelValue?: Ref<string | undefined> | string;
    as?: string;
    disabled?: boolean;
    resetable?: boolean;
}

const colorClass: Record<ColorEnum, string> = {
    primary: 'border-transparent ',
    secondary: 'border-gray-400',
    primaryFilled: '',
};

const props = withDefaults(defineProps<Props>(), {
    iconSide: 'right',
    color: ColorEnum.Primary,
    name: '',
    icon: null,
    placeholder: undefined,
    modelValue: undefined,
    as: 'input',
    disabled: false,
    resetable: false,
});

const emit = defineEmits<{
    'update:modelValue': [value: string | null];
    'focus': [FocusEvent];
}>();

function resetInput() {
    emit('update:modelValue', null);
}

const isInputFocused = ref<boolean>(false);

function onFocus(event: FocusEvent) {
    isInputFocused.value = true;
    emit('focus', event);
}
</script>

<template>
    <div
        :class="[
            colorClass[props.color],
            'group pointer-events-none flex items-center overflow-hidden rounded-2xl border bg-white ring-2 ring-inset',
            isInputFocused && 'border-transparent ring-border',
            !isInputFocused && ' ring-transparent',
        ]"
    >
        <span v-if="props.iconSide === 'left' && props.icon" class="px-4">
            <component
                :is="props.icon"
                :class="[isInputFocused && 'text-theme-green-500', 'group-hover:text-theme-green-500']"
            />
        </span>
        <Field
            :id="props.id"
            :type="props.type"
            :as="props.as"
            :placeholder="props.placeholder"
            :class="['pointer-events-auto bg-transparent focus-visible:outline-none', $attrs.class]"
            :name="props.id ?? props.name"
            :disabled="props.disabled"
            :model-value="props.modelValue"
            rows="4"
            @input="$emit('update:modelValue', $event.target.value)"
            @focus="onFocus"
            @focusout="isInputFocused = false"
        />
        <span v-if="props.resetable" :class="['pointer-events-auto flex w-4 justify-center pr-4']">
            <BaseButton
                :icon="IconCross"
                label=""
                :show-label="false"
                :class="['items-center text-border', props.modelValue && 'flex', !props.modelValue && 'hidden']"
                :default-classes="false"
                @click="resetInput"
            />
        </span>
        <span v-if="props.iconSide === 'right' && props.icon" class="pr-4">
            <component
                :is="props.icon"
                :class="[isInputFocused && 'text-theme-green-500', 'group-hover:text-theme-green-500']"
            />
        </span>
    </div>
</template>
