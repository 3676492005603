import AnimatedCamera from './AnimatedCamera';
import CustomTrackballControls from './controls/CustomTrackballControls';
import { ControlsOperation } from './controls/ICameraControls';
import type ICameraControls from './controls/ICameraControls';
import type { TCameraParams } from './TCameraParams';

class TrackballCamera extends AnimatedCamera {
    protected createControls(rendererDomElement: HTMLCanvasElement): ICameraControls {
        return new CustomTrackballControls(this, rendererDomElement);
    }

    constructor(cameraParams: TCameraParams) {
        super(cameraParams);
        this.initializeControls();
        this.controls.addMouseAction(ControlsOperation.ZOOM, 'WHEEL');
    }
}

export default TrackballCamera;
