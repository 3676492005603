import ScanController from '@/3d-app/scan/ScanController';
import { Mesh, MeshBasicMaterial, SphereGeometry } from 'three';

const POINT_RADIUS = 4;
const POINT_COLORS = [0x00ff00, 0xff0000, 0xffff00, 0x0000ff];

class AlignPointFactory {
    static getPoint(pointId: number): Mesh {
        const material = new MeshBasicMaterial({ color: POINT_COLORS[pointId] });
        const geometry = new SphereGeometry(POINT_RADIUS * ScanController.SCALE_TO_M_FACTOR, 16, 8);

        return new Mesh(geometry, material);
    }
}
export default AlignPointFactory;
