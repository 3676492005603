enum StoreEnum {
    AUTH = 'authStore',
    THREE_D = 'threeDStore',
    SCAN = 'scanStore',
    SETTINGS = 'settingsStore',
    ADMIN = 'adminStore',
    ORDER = 'orderStore',
    PATIENT = 'patientStore',
}

export default StoreEnum;
