const createNumberArray = ({ start, stop, step }: { start: number; stop: number; step: number }): Array<number> => {
    const array: Array<number> = [];

    for (let i = start; i <= stop; i += step) {
        array.push(i);
    }

    return array;
};

export default createNumberArray;
