import type AdminOfficeUserDTOType from '@/domains/admin/typescript/AdminOfficeUserDTOType';
import type UserProfileDTOInterface from '@/domains/authentication/typescript/UserProfileDTOInterface';
import type UserProfileInterface from '@/domains/authentication/typescript/UserProfileInterface';

function isUserProfileDTOInterface(
    userProfileDto: UserProfileDTOInterface | AdminOfficeUserDTOType,
): userProfileDto is UserProfileDTOInterface {
    return (
        (userProfileDto as UserProfileDTOInterface).office !== undefined &&
        (userProfileDto as UserProfileDTOInterface).orders !== undefined
    );
}

const transformDTOtoUserProfile = (
    userProfileDto: UserProfileDTOInterface | AdminOfficeUserDTOType,
): UserProfileInterface => {
    const userProfile = {
        '@id': userProfileDto['@id'],
        id: userProfileDto.id,
        userId: userProfileDto.user.id,
        firstname: userProfileDto.user.firstName,
        lastname: userProfileDto.user.lastName,
        email: userProfileDto.user.email,
        roles: userProfileDto.roles,
        holder: userProfileDto.holder,
    };

    if (isUserProfileDTOInterface(userProfileDto)) {
        return {
            ...userProfile,
            officeId: userProfileDto.office.id,
            orders: userProfileDto.orders,
        };
    }

    return {
        ...userProfile,
        officeId: '',
        orders: [],
    };
};

export default transformDTOtoUserProfile;
