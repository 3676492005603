<template>
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="7.21033" cy="7.68738" r="6.99304" fill="currentColor" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.30826 4.78525C4.60115 4.49236 5.07602 4.49236 5.36892 4.78525L7.21034 6.62667L9.05168 4.78532C9.34458 4.49243 9.81945 4.49243 10.1123 4.78532C10.4052 5.07821 10.4052 5.55309 10.1123 5.84598L8.271 7.68733L10.1123 9.5286C10.4052 9.8215 10.4052 10.2964 10.1123 10.5893C9.81938 10.8822 9.3445 10.8822 9.05161 10.5893L7.21034 8.74799L5.36899 10.5893C5.0761 10.8822 4.60122 10.8822 4.30833 10.5893C4.01544 10.2964 4.01544 9.82157 4.30833 9.52867L6.14968 7.68733L4.30826 5.84591C4.01536 5.55302 4.01536 5.07814 4.30826 4.78525Z"
            fill="white"
        />
    </svg>
</template>
