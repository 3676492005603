interface CallBackInterface {
    symbol: symbol;
    eventName: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    callback: Function;
}

class EventBus {
    callbacks: CallBackInterface[] = [];

    emit<T>(eventName: string, arg: T | null = null): void {
        this.callbacks.filter((callback) => callback.eventName === eventName).forEach(({ callback }) => callback(arg));
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    on(eventName: string, callback: Function): symbol {
        const symbol = Symbol('event-bus');
        this.callbacks = [...this.callbacks, { symbol, eventName, callback }];

        return symbol;
    }

    off(symbol: symbol): void {
        this.callbacks = this.callbacks.filter((callback) => callback.symbol !== symbol);
    }
}

const eventBus = new EventBus();

export default eventBus;
