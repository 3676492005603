import ScanController from '@/3d-app/scan/ScanController';

type Coord = {
    x: number;
    y: number;
};

const FootCoordinate = {
    heel: (isFootRight: boolean): Coord => {
        if (isFootRight) {
            return {
                x: (57.836251 + ScanController.XOFFSET) * ScanController.footRatio * ScanController.SCALE_TO_M_FACTOR,
                y: 304.989604 * ScanController.footRatio * ScanController.SCALE_TO_M_FACTOR,
            };
        }
        return {
            x: (71.125332 + ScanController.XOFFSET) * ScanController.footRatio * ScanController.SCALE_TO_M_FACTOR,
            y: 304.989604 * ScanController.footRatio * ScanController.SCALE_TO_M_FACTOR,
        };
    },

    meta1: (isFootRight: boolean): Coord => {
        if (isFootRight) {
            return {
                x: (25.868842 + ScanController.XOFFSET) * ScanController.footRatio * ScanController.SCALE_TO_M_FACTOR,
                y: 103.30162 * ScanController.footRatio * ScanController.SCALE_TO_M_FACTOR,
            };
        }
        return {
            x: (103.092741 + ScanController.XOFFSET) * ScanController.footRatio * ScanController.SCALE_TO_M_FACTOR,
            y: 103.30162 * ScanController.footRatio * ScanController.SCALE_TO_M_FACTOR,
        };
    },

    meta5: (isFootRight: boolean): Coord => {
        if (isFootRight) {
            return {
                x: (114.229714 + ScanController.XOFFSET) * ScanController.footRatio * ScanController.SCALE_TO_M_FACTOR,
                y: 127.541393 * ScanController.footRatio * ScanController.SCALE_TO_M_FACTOR,
            };
        }
        return {
            x: (14.731869 + ScanController.XOFFSET) * ScanController.footRatio * ScanController.SCALE_TO_M_FACTOR,
            y: 127.541393 * ScanController.footRatio * ScanController.SCALE_TO_M_FACTOR,
        };
    },
};

export default FootCoordinate;
