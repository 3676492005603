import type SortByInterface from '@/domains/common/typescript/SortByInterface';

function initSearchParams(searchParams: URLSearchParams | undefined = undefined): URLSearchParams {
    return searchParams !== undefined ? searchParams : new URLSearchParams();
}

function applyFilterParams(
    filters: Record<string, string>,
    currentParams: URLSearchParams | undefined = undefined,
): URLSearchParams {
    const params = initSearchParams(currentParams);
    Object.entries(filters).forEach(([key, value]) => {
        if (value !== undefined && value !== '') {
            params.append(key, value);
        }
    });
    return params;
}

function applySortParams(
    sortBy: SortByInterface,
    currentParams: URLSearchParams | undefined = undefined,
): URLSearchParams {
    const params = initSearchParams(currentParams);
    params.append(`order[${sortBy.param}]`, sortBy.value);
    return params;
}

function applyQueryParams(
    {
        newParams,
        currentParams,
    }: {
        newParams: { sortBy?: SortByInterface; filters?: Record<string, string> };
        currentParams: URLSearchParams | undefined;
    } = { newParams: {}, currentParams: undefined },
): URLSearchParams {
    const params = initSearchParams(currentParams);
    if (newParams.filters) {
        applyFilterParams(newParams.filters, params);
    }
    if (newParams.sortBy) {
        applySortParams(newParams.sortBy, params);
    }

    return params;
}

function applyPaginationParams(
    { page, limit }: { page?: number; limit?: number },
    currentParams: URLSearchParams | undefined = undefined,
): URLSearchParams {
    const params = initSearchParams(currentParams);
    if (page && limit) {
        params.append('page', page.toString());
        params.append('itemsPerPage', limit.toString());
    } else {
        params.append('pagination', 'false');
    }
    return params;
}

export { applyFilterParams, applySortParams, applyQueryParams, applyPaginationParams };
