import MeshUtils from '@/3d-app/commons/MeshUtils';
// eslint-disable-next-line import/extensions
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { Group, Mesh, MeshBasicMaterial, Texture } from 'three';

class NavigationMesh {
    private _navObject: Group;

    private _highlightedMesh: Mesh | undefined;

    private _defaultMat: MeshBasicMaterial;

    private _highlightMat: MeshBasicMaterial;

    private _textureMap: Texture | null;

    /**
     * Get navigation mesh
     */
    get mesh(): Group {
        return this._navObject;
    }

    constructor() {
        this._navObject = new Group();
        this._defaultMat = new MeshBasicMaterial();
        this._highlightMat = new MeshBasicMaterial();
        this._textureMap = null;
    }

    /**
     * Load the navigation mesh from the glb file
     * @returns a promise resolved when the mesh is fully loaded, with the Group mesh as parameter
     */
    loadMesh(): Promise<Group> {
        // Load the navigation cube mesh
        const gltfLoader = new GLTFLoader();

        return new Promise((resolve, reject) => {
            gltfLoader.load(
                // Resource URL
                '/models/NavCube.glb',
                // Called when the resource is loaded
                (gltf) => {
                    this._defaultMat = (gltf.scene.children[0] as Mesh).material as MeshBasicMaterial;
                    this._textureMap = this._defaultMat.map;
                    this._highlightMat = new MeshBasicMaterial({
                        lightMap: this._defaultMat.map,
                        color: 0x95d8e1,
                        lightMapIntensity: 4,
                    });
                    this._navObject = gltf.scene;
                    resolve(this._navObject);
                },
                undefined,
                reject,
            );
        });
    }

    /**
     * Hightlight on given mesh
     * @param mesh mesh to highlight (null to reset it)
     */
    highlightMesh(mesh: Mesh | null): void {
        // Remove old highlight
        if (this._highlightedMesh) {
            this._highlightedMesh.material = this._defaultMat;
        }
        if (mesh) {
            mesh.material = this._highlightMat;
            this._highlightedMesh = mesh;
        }
    }

    /**
     * Swap the navigation mesh to the grayed out version
     * @param isGrayedOut true if mesh should be grayed out, false for default colors
     */
    setGrayedOut(isGrayedOut: boolean): void {
        if (isGrayedOut) {
            this._defaultMat.map = null;
            this._defaultMat.color.setHex(0xaaaaaa);
        } else {
            this._defaultMat.map = this._textureMap;
            this._defaultMat.color.setHex(0xffffff);
        }
        this._defaultMat.needsUpdate = true;
    }

    /**
     * Free the resources allocated to the mesh and materials
     */
    dispose(): void {
        MeshUtils.disposeObject(this._navObject);
    }
}

export default NavigationMesh;
