interface CallbackInterface {
    symbol: Symbol;
    eventName: string;
    callback: Function;
}

class EventBus {
    callbacks: CallbackInterface[] = [];

    emit(eventName: string, ...args: any) {
        this.callbacks
            .filter((callback: CallbackInterface) => callback.eventName === eventName)
            .forEach(({ callback }: { callback: Function }) => callback(...args));
    }

    on(eventName: string, callback: Function) {
        const symbol = Symbol(eventName);
        this.callbacks = [...this.callbacks, { symbol, eventName, callback }];

        return symbol;
    }

    off(symbol: Symbol) {
        this.callbacks = this.callbacks.filter((callback: any) => callback.symbol !== symbol);
    }
}

const eventBus = new EventBus();

export default eventBus;
