import apiOffices from '@/domains/admin/api/office.api';
import apiAnnouncements from '@/domains/admin/api/announcement.api';
import { applyPaginationParams } from '@/domains/common/services/utils/buildFilterParams';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type AdminUserInterface from '@/domains/admin/typescript/AdminUserInterface';
import type AdminOfficeInterface from '@/domains/admin/typescript/AdminOfficeInterface';
import type AdminAnnouncementInterface from '@/domains/admin/typescript/AdminAnnouncementInterface';
import type AdminAnnouncementPageDisplayEnum from '../typescript/AdminAnnouncementPageDisplayEnum';

export default defineStore('admin', () => {
    const offices = ref<AdminOfficeInterface[]>();
    const users = ref<AdminUserInterface[]>();
    const announcements = ref<AdminAnnouncementInterface[]>();

    const selectedOffice = ref<AdminOfficeInterface>();
    const selectedUser = ref<AdminUserInterface>();
    const selectedAnnouncement = ref<AdminAnnouncementInterface>();

    const totalOffices = computed(() => offices.value?.length ?? 0);
    const totalUsers = ref(0);
    const totalAnnouncements = computed(() => announcements.value?.length ?? 0);

    const hasOfficeSelected = computed<boolean>(() => selectedOffice.value !== undefined);
    const hasUserSelected = computed<boolean>(() => selectedUser.value !== undefined);
    const hasAnnouncementSelected = computed<boolean>(() => selectedUser.value !== undefined);

    const selectOfficeById = (officeId: string): void => {
        selectedOffice.value = offices.value?.find((office) => office.id === officeId);
    };
    const selectUserById = (userId: string): void => {
        selectedUser.value = users.value?.find((user) => user.id === userId);
    };
    const selectAnnouncementById = (announcementId: AdminAnnouncementInterface['id']): void => {
        selectedAnnouncement.value = announcements.value?.find((announcement) => announcement.id === announcementId);
    };

    const isOfficeSelected = (office: AdminOfficeInterface): boolean => selectedOffice.value?.id === office.id;
    const isUserSelected = (user: AdminUserInterface): boolean => selectedUser.value?.id === user.id;
    const isAnnouncementSelected = (announcement: AdminAnnouncementInterface): boolean =>
        selectedAnnouncement.value?.id === announcement.id;

    const unselectOffice = (): void => {
        selectedOffice.value = undefined;
    };
    const unselectUser = (): void => {
        selectedUser.value = undefined;
    };
    const unselectAnnouncement = (): void => {
        selectedAnnouncement.value = undefined;
    };

    function getUserById(id: string): AdminUserInterface | undefined {
        return users.value?.find((user) => user.id === id);
    }

    const fetchOffices = async (page = 1, limit = 20) => {
        const searchParams = applyPaginationParams({ page, limit });
        const { entities } = await apiOffices.getOffices(searchParams);
        offices.value = entities;
    };

    function getOfficeById(id: string): AdminOfficeInterface | undefined {
        return offices.value?.find((office) => office.id === id);
    }

    const fetchAnnouncements = async (page = 1, limit = 10) => {
        const { entities } = await apiAnnouncements.getAnnouncements({ page, limit });
        announcements.value = entities;
    };

    const fetchAnnouncementsByPage = async (displayPage: AdminAnnouncementPageDisplayEnum) => {
        announcements.value = await apiAnnouncements.getAnnouncementsByPage(displayPage);
    };

    function getAnnouncementById(id: number): AdminAnnouncementInterface | undefined {
        return announcements.value?.find((announcement) => announcement.id === id);
    }

    const getActiveAnnouncements = computed<AdminAnnouncementInterface[] | undefined>(() => {
        return announcements.value?.filter((announcement) => announcement.isActivated);
    });

    function removeAnnouncement(id: number): void {
        const index = announcements.value?.findIndex((announcement) => announcement.id === id);
        if (index !== undefined && index !== -1) {
            announcements.value?.splice(index, 1);
        }
    }

    function $reset() {
        console.log('reset admin store');
        offices.value = undefined;
        users.value = undefined;
        selectedOffice.value = undefined;
        selectedUser.value = undefined;
    }

    return {
        offices,
        selectedOffice,
        hasOfficeSelected,
        totalOffices,
        selectOfficeById,
        isOfficeSelected,
        unselectOffice,
        getOfficeById,

        users,
        selectedUser,
        hasUserSelected,
        totalUsers,
        selectUserById,
        isUserSelected,
        unselectUser,
        getUserById,

        announcements,
        selectedAnnouncement,
        hasAnnouncementSelected,
        totalAnnouncements,
        selectAnnouncementById,
        isAnnouncementSelected,
        unselectAnnouncement,
        getAnnouncementById,
        getActiveAnnouncements,
        removeAnnouncement,

        fetchOffices,
        fetchAnnouncements,
        fetchAnnouncementsByPage,

        $reset,
    };
});
