<template>
    <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.0314 9.12373C8.51789 9.63381 5.44593 9.00033 2.21715 5.50137C3.4868 4.00542 5.5147 2.38838 7.93181 1.88234C10.4273 1.35991 13.4999 1.98843 16.7823 5.55231C15.5126 7.03771 13.4634 8.6302 11.0314 9.12373ZM18.3323 5.03671C14.6075 0.730866 10.8554 -0.262247 7.62444 0.414173C4.47101 1.07436 1.98773 3.29015 0.636945 5.07474L0.270508 5.55886L0.667733 6.01806C4.34053 10.2638 8.09171 11.2509 11.3297 10.5938C14.4932 9.95178 17.0065 7.77224 18.3631 5.98003L18.7295 5.49591L18.3323 5.03671ZM9.50001 8.73513C11.2556 8.73513 12.6789 7.28673 12.6789 5.50004C12.6789 3.71335 11.2556 2.26495 9.50001 2.26495C7.74437 2.26495 6.32114 3.71335 6.32114 5.50004C6.32114 7.28673 7.74437 8.73513 9.50001 8.73513Z"
            fill="currentColor"
        />
    </svg>
</template>
