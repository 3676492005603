enum ButtonColorEnum {
    Primary = 'primary',
    Secondary = 'secondary',
    Menu = 'menu',
    TablePrimary = 'tablePrimary',
    TableSecondary = 'tableSecondary',
    None = 'none',
}

export default ButtonColorEnum;
