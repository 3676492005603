import GenderOptionEnum from '@/domains/patient/typescript/PatientGenderOptionEnum';
import i18n from '@/translations';
import type SelectOptionInterface from '@/domains/common/typescript/SelectOptionInterface';

const { t } = i18n.global;

const GENDER_OPTIONS: SelectOptionInterface<GenderOptionEnum>[] = [
    {
        value: GenderOptionEnum.Man,
        label: t('patient.form_edit_patient.option_man'),
    },
    {
        value: GenderOptionEnum.Woman,
        label: t('patient.form_edit_patient.option_woman'),
    },
];

export default GENDER_OPTIONS;
