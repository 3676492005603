import transformDTOtoUserProfile from '@/domains/authentication/transformers/transformDTOtoUserProfile';
import type AdminOfficeDTOInterface from '@/domains/admin/typescript/AdminOfficeDTOInterface';
import type AdminOfficeInterface from '@/domains/admin/typescript/AdminOfficeInterface';

const transformDTOtoOffice = (officeDto: AdminOfficeDTOInterface): AdminOfficeInterface => {
    return {
        id: officeDto.id,
        name: officeDto.name,
        address: officeDto.address,
        phone: officeDto.phone,
        users: officeDto.officeUsers.map((officeUserDto) => transformDTOtoUserProfile(officeUserDto)),
        manufacturingType: officeDto.manufacturingType,
    };
};

export default transformDTOtoOffice;
