import AlignPointMode from './alignment/alignPoint/AlignPointMode';
import AlignScanMode from './alignment/alignScan/AlignScanMode';
import ElementMode from './elementPlacement/ElementMode';
import AlignSoleMode from './alignment/alignSole/AlignSoleMode';
import eventBus from '@/3d-app/commons/EventBus';
import type Mode from '@/3d-app/commons/Mode';

/**
 * Manager of all modes.
 * This class contains the current mode, and the activation/deactivation logic.
 * It's also the entry point of all modes; you can access a mode through it.
 */
class ModeManager {
    private _alignPointMode: AlignPointMode;

    private _alignScanMode: AlignScanMode;

    private _alignSoleMode: AlignSoleMode;

    private _elementMode: ElementMode;

    private _currentMode: Mode | null;

    get alignPointMode(): AlignPointMode {
        return this._alignPointMode;
    }

    get alignScanMode(): AlignScanMode {
        return this._alignScanMode;
    }

    get alignSoleMode(): AlignSoleMode {
        return this._alignSoleMode;
    }

    get elementMode(): ElementMode {
        return this._elementMode;
    }

    get currentMode(): Mode | null {
        return this._currentMode;
    }

    public constructor() {
        this._alignPointMode = new AlignPointMode();
        this._alignScanMode = new AlignScanMode();
        this._alignSoleMode = new AlignSoleMode();
        this._elementMode = new ElementMode();
        this._currentMode = null;
    }

    /**
     * Toggle on/off the given mode
     * @param mode The mode to toggle on/off
     */
    public toggleMode(mode: Mode): void {
        if (mode.enabled) {
            this.deactivateCurrentMode();
        } else {
            this.activateMode(mode);
        }
    }

    public activateMode(mode: Mode): void {
        if (this.currentMode !== mode) {
            this.currentMode?.deactivate(); // Deactivation
            this._currentMode = mode;
            this.currentMode?.activate(); // Then activation
            eventBus.emit('mode-changed', this.currentMode?.name);
        }
    }

    /**
     * Deactivate the currently activated mode
     */
    public deactivateCurrentMode(): void {
        if (this.currentMode) {
            // Deactivation
            this.currentMode.deactivate();
            this._currentMode = null;
            eventBus.emit('mode-changed', undefined);
        }
    }

    /**
     * Dispose all modes
     */
    public dispose(): void {
        this._alignPointMode.dispose();
        this._alignScanMode.dispose();
        this._alignSoleMode.dispose();
        this._elementMode.dispose();
    }
}

export default ModeManager;
