import transformDTOtoSoleElement from '@/domains/scan/services/transformDTOtoSoleElement';
import HydraAPI from '@/domains/common/services/hydraApi';
import type SoleConfigurationElementDTOInterface from '@/domains/scan/typescript/interfaces/SoleConfigurationElementDTOInterface';
import type SoleConfigurationElementInterface from '@/domains/scan/typescript/interfaces/SoleConfigurationElementInterface';
import type SoleConfigurationElementDTOCollectionInterface from '@/domains/scan/typescript/interfaces/SoleConfigurationElementDTOCollectionInterface';

const getElements = async (): Promise<Array<SoleConfigurationElementInterface>> => {
    const url = '/catalog_elements';
    const data = await HydraAPI.getJson<SoleConfigurationElementDTOCollectionInterface>(url);

    return data['hydra:member'].map((soleElement: SoleConfigurationElementDTOInterface) =>
        transformDTOtoSoleElement(soleElement),
    );
};

const getElement = async (
    id: SoleConfigurationElementDTOInterface['id'],
): Promise<SoleConfigurationElementInterface> => {
    const url = `/catalog_elements/${id}`;
    const data = await HydraAPI.getJson<SoleConfigurationElementDTOInterface>(url);

    return transformDTOtoSoleElement(data);
};
export default { getElements, getElement };
