import ManufactureTypeEnum from '@/domains/scan/typescript/enums/ManufactureTypeEnum';

const manufactureToNumber = (manufacture: ManufactureTypeEnum): number => {
    switch (manufacture) {
        case ManufactureTypeEnum.Milling:
            return 0;
        case ManufactureTypeEnum.Print:
            return 1;
        case ManufactureTypeEnum.All:
            return 2;
        default:
            return 0;
    }
};

const manufactureToDefaultValue = (manufacture?: ManufactureTypeEnum): number => {
    switch (manufacture) {
        case ManufactureTypeEnum.Milling:
            return 0;
        case ManufactureTypeEnum.Print:
            return 1;
        default:
            return 0;
    }
};

const manufactureToString = (manufacture: number): ManufactureTypeEnum => {
    switch (manufacture) {
        case 0:
            return ManufactureTypeEnum.Milling;
        case 1:
            return ManufactureTypeEnum.Print;
        case 2:
            return ManufactureTypeEnum.All;
        default:
            return ManufactureTypeEnum.Milling;
    }
};

export { manufactureToNumber, manufactureToString, manufactureToDefaultValue };
