import MeshUtils from '@/3d-app/commons/MeshUtils';
import { Group, Object3D, PerspectiveCamera, Vector3 } from 'three';
import type ElementMesh from '@/3d-app/scan/elementPlacement/meshes/ElementMesh';

/**
 * Base class of all gizmos
 */
class Gizmo extends Group {
    private readonly _transformFunction: (pos: Vector3) => void;

    private _isHighlighted: boolean;
    get isHighlighted(): boolean {
        return this._isHighlighted;
    }

    /**
     * Gizmo constructor
     * @param transformFunction function that will be called when the gizmo is used
     */
    public constructor(transformFunction: (pos: Vector3) => void) {
        super();
        this.renderOrder = 999; // Keep gizmo on front
        this._isHighlighted = false;
        this._transformFunction = transformFunction;
    }

    /**
     * Adapt given element scale to match camera distance
     * @param element the element to adapt
     */
    // eslint-disable-next-line class-methods-use-this
    public autoRescaleElement(element: Object3D) {
        element.onBeforeRender = (renderer, scene, camera) => {
            element.scale
                .divideScalar(25 / (camera.position.z - element.position.z))
                .divideScalar(75 / (camera as PerspectiveCamera).fov);
            element.updateWorldMatrix(false, false); // To transform update
        };
        element.onAfterRender = (renderer, scene, camera) => {
            element.scale
                .multiplyScalar(25 / (camera.position.z - element.position.z))
                .multiplyScalar(75 / (camera as PerspectiveCamera).fov); // Revert the previous changes
        };
    }

    /**
     * Highlight the gizmo
     * @param highlighted true to highlight, false to set to default
     */
    public setHighlighted(highlighted: boolean): void {
        this._isHighlighted = highlighted;
        // Highlight logic is defined in childs
    }

    /**
     * Update the gizmo to math attached mesh (position, rotation, scale)
     * @param attachedMesh the ElementMesh to adapt to
     */
    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
    public update(attachedMesh: ElementMesh): void {
        // This method will be overriden in childs
    }

    /**
     * Call saved transform method
     * @param pos 3D mouse position
     */
    public transform(pos: Vector3): void {
        this._transformFunction(pos);
    }

    /**
     * Dispose created objects
     */
    public dispose(): void {
        MeshUtils.disposeObject(this);
    }
}

export default Gizmo;
