import type AdminAnnouncementDTOInterface from '@/domains/admin/typescript/AdminAnnouncementDTOInterface';
import type AdminAnnouncementInterface from '@/domains/admin/typescript/AdminAnnouncementInterface';

function transformDTOtoAnnouncement(announcementDTO: AdminAnnouncementDTOInterface): AdminAnnouncementInterface {
    return {
        id: announcementDTO.id,
        text: announcementDTO.text,
        title: announcementDTO.title,
        color: announcementDTO.color as AdminAnnouncementInterface['color'],
        pictogram: announcementDTO.pictogram as AdminAnnouncementInterface['pictogram'],
        pageToDisplay: announcementDTO.pageToDisplay as AdminAnnouncementInterface['pageToDisplay'],
        isActivated: announcementDTO.isActivated,
        createdAt: new Date(announcementDTO.createdAt),
        updatedAt: new Date(announcementDTO.updatedAt),
    };
}

export default transformDTOtoAnnouncement;
