import GENDER_OPTIONS from '@/domains/patient/data/patient';
import type PatientDTOInterface from '@/domains/patient/typescript/PatientDTOInterface';
import type PatientInterface from '@/domains/patient/typescript/PatientInterface';

export default function transformDTOtoPatient(patient: PatientDTOInterface): PatientInterface {
    return {
        '@id': patient['@id'],
        id: patient.id,
        lastName: patient.lastname,
        firstName: patient.name,
        birthDate: patient.birthDate ? new Date(patient.birthDate) : undefined,
        phone: patient.phone,
        address: patient.address,
        email: patient.email,
        country: patient.country,
        gender: GENDER_OPTIONS.find((genderOption) => genderOption.value === patient.gender)?.value,
        city: patient.city,
        orders: patient.orders,
        zipCode: patient.zipCode,
        createdAt: new Date(patient.createdAt),
        creationDate: new Date(patient.creationDate),
    } as PatientInterface;
}
