import ScanController from '@/3d-app/scan/ScanController';
import MeshUtils from '@/3d-app/commons/MeshUtils';
import PictureLoader from '@/3d-app/commons/assetsManager/PictureLoader';
import { getRhinoLoader } from '@/3d-app/commons/RhinoUtils';
import {
    Box3,
    Group,
    Material,
    Matrix4,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    ObjectLoader,
    SRGBColorSpace,
    Texture,
    Vector3,
} from 'three';

class SoleMesh {
    private _soleObject: Group;

    private _soleGeometry: Object3D;

    private _attached: Group;

    private _materials: Array<Material>;

    private _boundingBox: Box3;

    private _isReady: boolean;

    private _isVisible: boolean;

    /**
     * Get the whole sole, with attached childs
     */
    get mesh(): Group {
        return this._soleObject;
    }

    /**
     * Get the sole ready status (ready is fully loaded)
     */
    get isReady(): boolean {
        return this._isReady;
    }

    /**
     * Get only attached childs, without the sole geometry
     */
    get attached(): Group {
        return this._attached;
    }

    /**
     * Get only the sole geometry, without the attached childs
     */
    get soleGeometry(): Object3D {
        return this._soleGeometry;
    }

    constructor() {
        this._soleGeometry = new Object3D();
        this._soleGeometry.name = 'soleGeometry';
        this._attached = new Group();
        this._attached.name = 'attached';
        this._soleObject = new Group();
        this._soleObject.name = 'soleObject';
        this._materials = [];
        this._boundingBox = new Box3();
        this._isVisible = true;
        this._isReady = false;
    }

    get boundingBox(): Box3 {
        return this._boundingBox;
    }

    get origin(): Vector3 {
        return this._soleGeometry.position;
    }

    private resetSole(): void {
        this._isReady = false;
        MeshUtils.disposeObject(this._soleGeometry);

        this._materials.forEach((material) => (material as MeshBasicMaterial).map?.dispose()); // Dispose all textures
        this._materials = [];
    }

    /**
     * Load the sole mesh from 3dm ArrayBuffer or from default asset
     * @param isRightSole true if given sole is the right one, false for left one
     * @param sole ArrayBuffer containing the sole 3dm
     * @param adaptScale boolean indicating wether the loaded sole must be scaled to current foot size (true default)
     * @returns promise resolved when object is loaded, with the loaded group
     */
    loadSole(isRightSole: boolean, sole: ArrayBuffer): Promise<Group> {
        if (sole) {
            this.resetSole();
        } // Remove previous sole

        // Load the 3DM
        const loader = getRhinoLoader();

        const adaptSole = (object3D: Object3D) => {
            object3D.scale.setScalar(ScanController.SCALE_TO_M_FACTOR); // Resize the sole to the correct foot size

            const bbox = new Box3().setFromObject(object3D); // Get bounding box

            const posDiff = bbox.min.add(bbox.max).divideScalar(-2); // Find object center with bounding box info

            const templateMesh = ScanController.instance?.getTemplateMesh(isRightSole);
            // It exist 1/2 or 3/4 sole and we dont want to center those kind of soles,
            // for Y axis we want to stick to template bottom, idea is that heels are aligned
            let posY = posDiff.y;
            if (templateMesh) {
                posY = templateMesh?.boundingBox.min.y;
            }
            object3D.applyMatrix4(new Matrix4().makeTranslation(posDiff.x, posY, 0));

            // Update object orientation
            object3D.applyMatrix4(new Matrix4().makeRotationX(Math.PI));

            this._soleGeometry = object3D;
            this._soleGeometry.name = 'soleGeometry';
            this._soleObject.position.set(0, 0, 0); // Reset previous sole position
            this._soleObject.add(this._soleGeometry);

            this._soleObject.remove(this._attached); // Remove attached to compute bounding box

            this._boundingBox.setFromObject(this._soleObject); // Get final bounding box

            this._soleObject.add(this._attached);

            // Offset the position for right or left
            this._soleObject.position.x = ScanController.getFootOffset(isRightSole);

            this.updateVisibility(); // Update visibility

            // Set sole status to ready
            this._isReady = true;
        };

        // Load the 3DM file
        return new Promise((resolve, reject) => {
            loader.parse(
                sole,
                (object3D) => {
                    // Keep reference to the materials and add transparency to them
                    object3D.traverse((soleObject3D) => {
                        if (soleObject3D instanceof Mesh) {
                            const originalMat = soleObject3D.material;
                            soleObject3D.material = originalMat.clone();
                            originalMat.dispose();
                            const convMat = soleObject3D.material as MeshBasicMaterial;
                            convMat.transparent = true;
                            convMat.alphaTest = 0.05;

                            this._materials.push(soleObject3D.material);
                        }
                    });

                    this.loadMapsFromMaterialNames().then(() => {
                        adaptSole(object3D);
                        resolve(this._soleObject);
                    });
                },
                (error) => reject(error),
            );
        });
    }

    private loadMapsFromMaterialNames(): Promise<void[]> {
        const promises: Promise<void>[] = [];
        this._materials.forEach((material) => {
            if (material.name.startsWith('/materials/') && material.name !== '/materials/.jpg') {
                const wasLoaded = PictureLoader.isLoadedPicture(PictureLoader.getNameFromPath(material.name));
                promises.push(
                    PictureLoader.loadPicture(material.name)
                        .then((texture) => {
                            texture.colorSpace = SRGBColorSpace;
                            (material as MeshBasicMaterial).map = texture;
                            (material as MeshBasicMaterial).color.convertLinearToSRGB();
                            (material as MeshBasicMaterial).color.multiplyScalar(4);

                            if (!wasLoaded) {
                                // Force texture init in GPU to prevent first load lag
                                ScanController.instance?.renderer.initTexture(texture);
                            }
                        })
                        .catch(console.error),
                );
            }
        });
        return Promise.all(promises);
    }

    setOpacity(opacity: number): void {
        this._materials.forEach((material) => {
            material.opacity = opacity;
        });
        this.updateVisibility();
    }

    getOpacity(): number {
        if (this._materials.length > 0) {
            return this._materials[0].opacity;
        }
        return 1;
    }

    setIsVisible(isVisible: boolean): void {
        this._isVisible = isVisible;
        this.updateVisibility();
    }

    getIsVisible(): boolean {
        return this._isReady && this._isVisible;
    }

    updateVisibility(): void {
        let isCurrentOrVisible = true;
        if (ScanController.instance) {
            isCurrentOrVisible =
                ScanController.instance.isOtherFootVisible || ScanController.instance.currentSoleMesh === this;
        }
        this._soleGeometry.visible = this._isVisible && isCurrentOrVisible && this.getOpacity() > 0;
        this._attached.visible = isCurrentOrVisible;
    }

    toJSON(): any {
        // Save current opacity
        const savedVisibility = this._soleGeometry.visible;
        let savedOpacity = 1;
        if (this._materials.length > 0) {
            savedOpacity = this._materials[0].opacity;
        }
        this.setOpacity(1); // Save at full opacity (we could also save current opacity)
        this._soleGeometry.visible = true;
        // Temporarily remove attached for export
        this._soleObject.remove(this._attached);

        // Temporarily remove the maps
        const textures: Array<Texture | null> = [];
        const mappedMaterials = this._materials.filter((material) => material.name.startsWith('/materials/'));
        mappedMaterials.forEach((material) => {
            textures.push((material as MeshBasicMaterial).map);
            (material as MeshBasicMaterial).map = null;
        });

        const json = this._soleObject.toJSON();

        // Restore the maps
        for (let i = 0; i < mappedMaterials.length; i++) {
            (mappedMaterials[i] as MeshBasicMaterial).map = textures[i];
        }

        this._soleObject.add(this._attached); // Reattach previously attached
        this._soleGeometry.visible = savedVisibility;
        this.setOpacity(savedOpacity); // Restore opacity
        return json;
    }

    fromJSON(json: any): Promise<Group | undefined> {
        this.dispose();
        const loader = new ObjectLoader();

        return new Promise((resolve) => {
            loader.parse(json, (obj) => {
                const loadedSole = obj as Group;

                if (loadedSole.children.length > 0) {
                    // If there is a sole
                    this._soleGeometry = loadedSole.children[0];
                    this._soleGeometry.name = 'soleGeometry';

                    this._soleGeometry.traverse((soleObject) => {
                        if (soleObject instanceof Mesh) {
                            this._materials.push(soleObject.material);
                        }
                    });

                    this.loadMapsFromMaterialNames().then(() => {
                        this._soleObject.add(this._soleGeometry);

                        this._attached = new Group();
                        this._attached.name = 'attached';
                        this._soleObject.attach(this._attached);

                        // Compute bounding box
                        this._boundingBox = new Box3().setFromObject(this._soleObject);

                        // Offset the position
                        this._soleObject.position.x = loadedSole.position.x;

                        this._isReady = true;
                        this.updateVisibility();

                        resolve(this._soleObject);
                    });
                } else {
                    resolve(undefined);
                }
            });
        });
    }

    dispose(): void {
        this._materials.forEach((material) => (material as MeshBasicMaterial).map?.dispose()); // Dispose all textures
        MeshUtils.disposeObject(this._soleObject);
        this._soleObject = new Group();
        this._soleObject.name = 'soleObject';
        this._materials.length = 0;
    }
}

export default SoleMesh;
