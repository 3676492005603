import i18n from '@/translations';

const { t } = i18n.global;

const messageMatching: { [key: string]: string } = {
    'Expired JWT Token': 'login.session_expired',
    'JWT Token not found': 'login.session_expired',
    'Invalid JWT Token': 'login.session_expired',
    'No sole computed by rhino': 'scan.rhino.no_sole_computed',
    'Error, internal server error.': 'api.500',
};

function userFriendlyMessageConverter(message: string): string | void {
    return messageMatching[message] ? t(messageMatching[message]) : message;
}

export default userFriendlyMessageConverter;
