enum HttpStatusCodeEnum {
    OK = 200,
    CREATED = 201,
    NO_CONTENT = 204,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    NOT_FOUND = 404,
    FORBIDDEN = 403,
    LOCKED = 423,
    INTERNAL_SERVER_ERROR = 500,
}

export default HttpStatusCodeEnum;
