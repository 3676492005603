<script setup lang="ts">
import router from '@/router';
import { isParamString } from '@/router/routeParamType';
import RouteNameEnum from '@/router/routeNameEnum';
import { resetPassword } from '@/domains/authentication/services/authenticate';
import resetPasswordSchema from '@/domains/authentication/validators/resetPassword.schema';
import BaseErrorMessage from '@/domains/common/components/BaseErrorMessage.vue';
import BaseInputLabel from '@/domains/common/components/BaseInputLabel.vue';
import BaseInputPassword from '@/domains/common/components/BaseInputPassword.vue';
import BaseButton from '@/domains/common/components/BaseButton.vue';
import ButtonColorEnum from '@/domains/common/typescript/ButtonColorEnum';
import ColorEnum from '@/domains/common/typescript/ColorEnum';
import AuthenticationLayout from '@/layouts/AuthenticationLayout.vue';
import { useForm } from 'vee-validate';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import type ResetPasswordFormInterface from '@/domains/authentication/typescript/ResetPasswordFormInterface';

const route = useRoute();

const { values, errors, handleSubmit } = useForm<ResetPasswordFormInterface>({
    validationSchema: resetPasswordSchema,
    initialValues: {
        password: '',
    },
});

const isFormValid = computed(() => Object.keys(errors.value).length === 0);

const registerForm = async () => {
    try {
        const token = isParamString(route.params.token) ? route.params.token : '';
        await resetPassword(values.password, token);
        router.push({ name: RouteNameEnum.Login });
    } catch (error) {
        throw new Error(`Error on save data: ${error}`);
    }
};

function onSubmit(event: Event) {
    handleSubmit(registerForm)(event);
}
</script>

<template>
    <AuthenticationLayout>
        <div class="mx-auto w-full max-w-sm lg:w-96">
            <h1 class="text-center text-3xl uppercase">
                {{ $t('login.title_reset_password') }}
            </h1>
            <form action="#" method="POST" class="mt-10 flex flex-col gap-6" @submit="onSubmit">
                <div>
                    <BaseInputLabel target="password">{{ $t('login.new_password') }}</BaseInputLabel>
                    <BaseInputPassword
                        id="password"
                        class="text-input"
                        autocomplete="password"
                        :color="ColorEnum.Secondary"
                        :name="$t('login.new_password')"
                        :display-strength="true"
                    />
                    <BaseErrorMessage field-name="password" />
                </div>
                <div>
                    <BaseInputLabel target="confirm_password">{{ $t('login.verify_password') }}</BaseInputLabel>
                    <BaseInputPassword
                        id="confirm_password"
                        class="text-input"
                        autocomplete="password"
                        :color="ColorEnum.Secondary"
                        :name="$t('login.verify_password')"
                    />
                    <BaseErrorMessage field-name="confirm_password" />
                </div>
                <div>
                    <BaseButton
                        class="w-full"
                        :disabled="!isFormValid"
                        :color="ButtonColorEnum.Primary"
                        :label="$t('login.reset_password_submit')"
                        type="submit"
                    />
                </div>
            </form>
        </div>
    </AuthenticationLayout>
</template>
