<template>
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8.77533" cy="8.17578" r="7.25" stroke="black" stroke-width="1.5" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.78741 4.23584C9.20163 4.23584 9.53741 4.57163 9.53741 4.98584V9.02666C9.53741 9.44087 9.20163 9.77666 8.78741 9.77666C8.3732 9.77666 8.03741 9.44087 8.03741 9.02666L8.03741 4.98584C8.03741 4.57163 8.3732 4.23584 8.78741 4.23584Z"
            fill="#0B1B06"
        />
        <circle cx="8.77533" cy="11.3534" r="0.762085" fill="#0B1B06" />
    </svg>
</template>
