<script setup lang="ts">
import IconCheckRounded from '@/domains/common/components/icons/IconCheckRounded.vue';
import IconWarning from '@/domains/common/components/icons/IconWarning.vue';
import NotificationStatus from '@/domains/common/typescript/NotificationStatus';
import { XCircleIcon, XMarkIcon, InformationCircleIcon } from '@heroicons/vue/20/solid';
import { ref, shallowRef, watchEffect } from 'vue';

const emit = defineEmits<{ (e: 'hide-message'): void }>();

const props = defineProps<{
    message: string;
    status: NotificationStatus;
}>();

const CheckRoundedIcon = shallowRef(IconCheckRounded);
const WarningIcon = shallowRef(IconWarning);

const textColor = ref('');
const borderColor = ref('');
const backgroundColor = ref('');
const icon = ref();

watchEffect(() => {
    switch (props.status) {
        case NotificationStatus.success:
            textColor.value = 'text-success';
            borderColor.value = 'border-success';
            backgroundColor.value = 'bg-success/50';
            icon.value = CheckRoundedIcon;
            break;
        case NotificationStatus.warning:
            textColor.value = 'text-warning';
            borderColor.value = 'border-warning';
            backgroundColor.value = 'bg-warning/50';
            icon.value = WarningIcon;
            break;
        case NotificationStatus.error:
            textColor.value = 'text-error';
            borderColor.value = 'border-error';
            backgroundColor.value = 'bg-error/50';
            icon.value = XCircleIcon;
            break;
        case NotificationStatus.info:
            textColor.value = 'text-blue-700';
            borderColor.value = 'border-blue-700';
            backgroundColor.value = 'bg-blue-700/50';
            icon.value = InformationCircleIcon;
            break;
        default:
            textColor.value = 'text-gray-900';
            borderColor.value = 'border-gray-900';
            backgroundColor.value = 'bg-gray-900/50';
            icon.value = null;
    }
});
</script>
<template>
    <div
        :class="[
            'pointer-events-auto w-full max-w-md overflow-hidden rounded-3xl border-2 bg-white shadow-lg ring-1 ring-black ring-opacity-5',
            textColor,
            borderColor,
        ]"
    >
        <div class="px-6 py-8">
            <div :class="['flex items-center']">
                <div class="flex-shrink-0">
                    <component :is="icon" class="h-6 w-6" />
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                    <p class="text-lg font-semibold">
                        {{ props.message }}
                    </p>
                </div>
                <div class="ml-4 flex flex-shrink-0">
                    <button
                        type="button"
                        :class="['inline-flex rounded-full bg-transparent p-1 transition-colors']"
                        @click="emit('hide-message')"
                    >
                        <span class="sr-only">{{ $t('notification.close') }}</span>
                        <XMarkIcon class="h-6 w-6" />
                    </button>
                </div>
            </div>
        </div>
        <div :class="['h-1 animate-countdown-width', backgroundColor]" />
    </div>
</template>
