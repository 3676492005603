import { Vector3 } from 'three';

const directions: { [key: string]: Vector3 } = {
    Top: new Vector3(0, 0, 1),
    Bottom: new Vector3(0, 0, -1),
    Left: new Vector3(-1, 0, 0),
    Right: new Vector3(1, 0, 0),
    Front: new Vector3(0, -1, 0),
    Back: new Vector3(0, 1, 0),
    TopRight: new Vector3(1, 0, 1).normalize(),
    TopLeft: new Vector3(-1, 0, 1).normalize(),
    TopFront: new Vector3(0, -1, 1).normalize(),
    TopBack: new Vector3(0, 1, 1).normalize(),
    BottomRight: new Vector3(1, 0, -1).normalize(),
    BottomLeft: new Vector3(-1, 0, -1).normalize(),
    BottomFront: new Vector3(0, -1, -1).normalize(),
    BottomBack: new Vector3(0, 1, -1).normalize(),
    FrontRight: new Vector3(1, -1, 0).normalize(),
    FrontLeft: new Vector3(-1, -1, 0).normalize(),
    BackRight: new Vector3(1, 1, 0).normalize(),
    BackLeft: new Vector3(-1, 1, 0).normalize(),
    TopFrontRight: new Vector3(1, -1, 1).normalize(),
    TopFrontLeft: new Vector3(-1, -1, 1).normalize(),
    TopBackRight: new Vector3(1, 1, 1).normalize(),
    TopBackLeft: new Vector3(-1, 1, 1).normalize(),
    BottomFrontRight: new Vector3(1, -1, -1).normalize(),
    BottomFrontLeft: new Vector3(-1, -1, -1).normalize(),
    BottomBackRight: new Vector3(1, 1, -1).normalize(),
    BottomBackLeft: new Vector3(-1, 1, -1).normalize(),
};

const OrbitDirection = {
    to: (direction: string): Vector3 => directions[direction].clone(),
};

export default OrbitDirection;
