import eventBus from './eventBus';
import userFriendlyMessageConverter from './userFriendlyMessageConverter';
import NotificationStatus from '@/domains/common/typescript/NotificationStatus';
import type HttpStatusCodeEnum from '@/domains/common/typescript/HttpStatusCodeEnum';
import type { ComponentPublicInstance } from 'vue';

function handleHttpError(code: HttpStatusCodeEnum, message: string, showToast = true): void {
    if (showToast) {
        const userFriendlyMessage = userFriendlyMessageConverter(message);
        eventBus.emit('notification-message', {
            title: userFriendlyMessage,
            status: NotificationStatus.error,
        });
    }
    throw new Error(message);
}

function handleRenderError(error: Error, instance: ComponentPublicInstance | null, info: string, showToast = true) {
    if (showToast) {
        eventBus.emit('notification-message', {
            title: error?.message ?? info,
            status: NotificationStatus.error,
        });
    }
}

export { handleHttpError, handleRenderError };
