import transformDTOtoAnnouncement from '@/domains/admin/transformers/transformDTOtoAnnouncement';
import { applyPaginationParams } from '@/domains/common/services/utils/buildFilterParams';
import HydraAPI from '@/domains/common/services/hydraApi';
import type AdminAnnouncementInterface from '@/domains/admin/typescript/AdminAnnouncementInterface';
import type AdminAnnouncementDTOCollectionInterface from '@/domains/admin/typescript/AdminAnnouncementDTOCollectionInterface';
import type AdminAnnouncementDTOInterface from '@/domains/admin/typescript/AdminAnnouncementDTOInterface';
import type AdminAnnouncementFormInterface from '@/domains/admin/typescript/AdminAnnouncementFormInterface';
import type PaginatedEntitiesInterface from '@/domains/common/typescript/PaginatedEntitiesInterface';
import type AdminAnnouncementPageDisplayEnum from '@/domains/admin/typescript/AdminAnnouncementPageDisplayEnum';

const getAnnouncements = async (
    { page, limit }: { page: number; limit: number } = { page: 1, limit: 20 },
): Promise<PaginatedEntitiesInterface<AdminAnnouncementInterface>> => {
    const searchParams = applyPaginationParams({ page, limit });
    const url = !searchParams ? '/announcements' : `/announcements?${searchParams.toString()}`;
    const data = await HydraAPI.getJson<AdminAnnouncementDTOCollectionInterface>(url);
    const announcements = data['hydra:member'].map((announcementDTO) => transformDTOtoAnnouncement(announcementDTO));
    return { entities: announcements, total: data['hydra:totalItems'] };
};

const getAnnouncementsByPage = async (
    displayPage: AdminAnnouncementPageDisplayEnum,
): Promise<AdminAnnouncementInterface[]> => {
    const searchParams = new URLSearchParams();
    searchParams.append('pageToDisplay', displayPage);
    const url = `/announcements?${searchParams.toString()}`;
    const data = await HydraAPI.getJson<AdminAnnouncementDTOCollectionInterface>(url);
    return data['hydra:member'].map((announcementDTO) => transformDTOtoAnnouncement(announcementDTO));
};

const getAnnouncement = async (id: AdminAnnouncementInterface['id']): Promise<AdminAnnouncementInterface> => {
    const url = `/announcements/${id}`;
    const data = await HydraAPI.getJson<AdminAnnouncementDTOInterface>(url);
    return transformDTOtoAnnouncement(data);
};

const createAnnouncement = async (
    announcementForm: AdminAnnouncementFormInterface,
): Promise<AdminAnnouncementInterface> => {
    const url = '/announcements';
    const body = JSON.stringify(announcementForm);
    const data = await HydraAPI.postJson<AdminAnnouncementDTOInterface>(url, { body });

    return transformDTOtoAnnouncement(data);
};

const updateAnnouncement = async ({
    form,
    id,
}: {
    form: AdminAnnouncementFormInterface;
    id: AdminAnnouncementInterface['id'];
}): Promise<AdminAnnouncementInterface> => {
    const url = `/announcements/${id}`;
    const body = JSON.stringify(form);
    const data = await HydraAPI.putJson<AdminAnnouncementDTOInterface>(url, { body });

    return transformDTOtoAnnouncement(data);
};

const deleteAnnouncement = async (id: AdminAnnouncementInterface['id']): Promise<void> => {
    const url = `/announcements/${id}`;
    await HydraAPI.deleteJson(url);
};

export default {
    getAnnouncements,
    getAnnouncementsByPage,
    getAnnouncement,
    createAnnouncement,
    updateAnnouncement,
    deleteAnnouncement,
};
