import type JsonConfigurationInfos from 'src/domains/scan/typescript/interfaces/JsonConfigurationInfos';
import type ConfigurationInfosInterface from '@/domains/scan/typescript/interfaces/ConfigurationInfosInterface';

const jsonConfigurationAdapter = (config: JsonConfigurationInfos[]): ConfigurationInfosInterface[] =>
    config.map((innerConf) => {
        if (!innerConf['Fabrication fraisage'] && !innerConf['Fabrication Impression']) {
            return {
                label: innerConf.Label,
                order: innerConf['Order value'].toString(),
                name: innerConf['Order name'],
            };
        }

        return {
            label: innerConf.Label,
            order: innerConf['Order value'].toString(),
            name: innerConf['Order name'],
            milling: innerConf['Fabrication fraisage'] === 'Oui',
            printing: innerConf['Fabrication Impression'] === 'Oui',
        };
    });

export default jsonConfigurationAdapter;
