<script lang="ts" setup>
import getClassesFromColor from '@/domains/common/services/getBaseButtonColor';
import ButtonColorEnum from '@/domains/common/typescript/ButtonColorEnum';
import { computed } from 'vue';

interface Props {
    icon?: Object;
    label: string;
    showLabel?: boolean;
    target?: string;
    link: string;
    defaultClasses?: boolean;
    color?: ButtonColorEnum;
}

const props = withDefaults(defineProps<Props>(), {
    icon: undefined,
    showLabel: true,
    target: '_blank',
    defaultClasses: true,
    color: ButtonColorEnum.None,
});

const colorClasses = computed(() => getClassesFromColor(props.color, false));
</script>

<template>
    <a
        :href="link"
        :class="[
            defaultClasses && 'flex cursor-pointer items-center justify-center rounded-2xl px-5 py-2 font-semibold',
            $attrs.class,
            colorClasses.background,
            colorClasses.text,
        ]"
        :target="props.target"
    >
        <span :class="[props.icon && 'mr-2', !props.showLabel && 'sr-only']">{{ props.label }}</span>
        <component :is="props.icon" v-if="props.icon !== undefined" :aria-hidden="!props.showLabel" />
    </a>
</template>
