import router from '@/router';
import RouteNameEnum from '@/router/routeNameEnum';
import {
    getAuthenticationToken,
    postForgotPasword,
    postResetPassword,
} from '@/domains/authentication/api/authentication.api';
import useAuthStore from '@/domains/authentication/store/auth.store';
import useSettingsStore from '@/domains/settings/store/settings.store';
import eventBus from '@/domains/common/services/eventBus';
import NotificationStatus from '@/domains/common/typescript/NotificationStatus';
import HttpStatusCodeEnum from '@/domains/common/typescript/HttpStatusCodeEnum';
import HydraAPI from '@/domains/common/services/hydraApi';
import { handleHttpError } from '@/domains/common/services/errorHandler';
import useResetStore from '@/domains/common/services/useResetStore';
import * as Sentry from '@sentry/vue';
import type LoginCredentialsInterface from '@/domains/authentication/typescript/LoginCredentialsInterface';
import type UserInterface from '@/domains/authentication/typescript/UserInterface';

const login = async ({ username, password }: LoginCredentialsInterface) => {
    try {
        await getAuthenticationToken(username, password);
        await updateToken();

        router.push({ name: RouteNameEnum.SelectOffices });

        eventBus.emit('notification-message', {
            title: 'login.success',
            status: NotificationStatus.success,
        });

        Sentry.setUser({ email: username });
    } catch (error) {
        eventBus.emit('notification-message', {
            title: 'global.error',
            status: NotificationStatus.error,
        });
    }
};

const checkTokenIsExpired = (jsonBody: any, httpStatus: HttpStatusCodeEnum): void => {
    if (httpStatus === HttpStatusCodeEnum.UNAUTHORIZED || jsonBody.code === HttpStatusCodeEnum.UNAUTHORIZED) {
        logout({ shouldLoginRedirect: true });
    }
};

const logout = async ({ shouldLoginRedirect = true }: { shouldLoginRedirect: boolean }) => {
    const { resetStores } = useResetStore();
    const authStore = useAuthStore();
    authStore.isUserLoggedIn = false;
    resetStores();
    Sentry.setUser(null);

    HydraAPI.getJson('/logout');
    if (shouldLoginRedirect) {
        router.push({ name: RouteNameEnum.Login });
    }
};

const updateToken = async (): Promise<void> => {
    try {
        const authStore = useAuthStore();
        const settingsStore = useSettingsStore();
        const response = await HydraAPI.getJson<UserInterface>('/users/current-user');
        await authStore.initUserInfos(response);
        authStore.isUserLoggedIn = true;
        settingsStore.manufacturePreferencesIri = response.manufacturePreferences;
        settingsStore.podonexConfigurationIri = response.podonextConfiguration;
        await settingsStore.fetchManufacturePreferences();
        await settingsStore.fetchPodonexConfiguration();
    } catch (e) {
        router.push({ name: RouteNameEnum.Login });
    }
};

const forgottenPassword = async (email: string) => {
    const response = await postForgotPasword(email);

    if (response.status === HttpStatusCodeEnum.BAD_REQUEST) {
        handleHttpError(response.status, 'login.forgotten_password_error');
    }

    router.push({ name: RouteNameEnum.Login });

    eventBus.emit('notification-message', {
        title: 'login.forgotten_password_success',
        status: NotificationStatus.success,
    });
};

const resetPassword = async (password: string, token: string) => {
    const response = await postResetPassword(password, token);

    if (response.ok) {
        eventBus.emit('notification-message', {
            title: 'login.reset_password_success',
            status: NotificationStatus.success,
        });
    } else {
        handleHttpError(response.status, 'login.reset_password_error');
    }

    router.push({ name: RouteNameEnum.Login });
};

export { login, logout, checkTokenIsExpired, updateToken, forgottenPassword, resetPassword };
