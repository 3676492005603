//   Example which will match:
//   const targetString = "This is an Example-String_with Spaces";
//   const stringToCheck = "examplestring"

function isStringContained(stringToCheck: string, targetString: string) {
    // Normalize both strings by removing special characters and converting to lowercase
    const normalizedStringToCheck = stringToCheck.replace(/[\s\-_]/g, '').toLowerCase();
    const normalizedTargetString = targetString.replace(/[\s\-_]/g, '').toLowerCase();

    return normalizedTargetString.includes(normalizedStringToCheck);
}

// eslint-disable-next-line import/prefer-default-export
export { isStringContained };
