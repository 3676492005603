import scanApi from '@/domains/scan/api/scan.api';

async function isPodonexReady(): Promise<boolean> {
    let isScanReady = false;

    try {
        await scanApi.hello();
        isScanReady = true;
    } catch {
        isScanReady = false;
    }

    return isScanReady;
}

export default isPodonexReady;
