import { handleHttpError } from '@/domains/common/services/errorHandler';
import HydraAPI from '@/domains/common/services/hydraApi';
import i18n from '@/translations';
import transformDTOtoUserProfile from '@/domains/authentication/transformers/transformDTOtoUserProfile';
import type UserProfileInterface from '@/domains/authentication/typescript/UserProfileInterface';
import type UserProfileDTOInterface from '@/domains/authentication/typescript/UserProfileDTOInterface';
import type AdminOfficeUserEditDTOInterface from '@/domains/admin/typescript/AdminOfficeUserEditDTOInterface';

const { t } = i18n.global;

const API_URI = import.meta.env.VITE_API_URI;

const getAuthenticationToken = async (email: string, password: string): Promise<any> => {
    const response = await fetch(`${API_URI}/authentication_token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email,
            password,
        }),
        credentials: 'include',
    });

    if (response.status === 401) {
        handleHttpError(response.status, t('login.wrong_credentials'));
    }

    if (response.status === 404) {
        handleHttpError(response.status, t('api.404'));
    }

    const data = await response.json();

    if (data.status === 'error') {
        handleHttpError(response.status, data.message);
    }

    return data;
};

const getUserProfile = async (userOfficeId: string): Promise<UserProfileInterface> => {
    let url = `/office_users/${userOfficeId}`;
    if (userOfficeId.includes('office_users/')) {
        url = userOfficeId;
    }

    const userProfile = await HydraAPI.getJson<UserProfileDTOInterface>(url);

    return transformDTOtoUserProfile(userProfile);
};

const postForgotPasword = async (email: string): Promise<Response> => {
    const response = await fetch(`${API_URI}/forgot_password/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            email,
        }),
    });

    return response;
};

const postResetPassword = async (password: string, token: string): Promise<Response> => {
    const response = await fetch(`${API_URI}/forgot_password/${token}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            password,
        }),
    });

    return response;
};

const updateCurrentOffice = async (
    userOfficeId: string,
    officeUser: Pick<AdminOfficeUserEditDTOInterface, 'user' | 'office'>,
): Promise<void> => {
    let url = `/office_users/${userOfficeId}/update`;
    if (userOfficeId.includes('office_users/')) {
        url = userOfficeId;
    }
    const body = JSON.stringify(officeUser);
    HydraAPI.postJson<UserProfileDTOInterface>(url, { body });
};

export { getAuthenticationToken, getUserProfile, postForgotPasword, postResetPassword, updateCurrentOffice };
