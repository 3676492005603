import ManufactureTypeEnum from '@/domains/scan/typescript/enums/ManufactureTypeEnum';
import type SettingsInterface from '@/domains/settings/typescript/interfaces/SettingsInterface';
import type SettingsDTOInterface from '@/domains/settings/typescript/interfaces/SettingsDTOInterface';

export default function transformDTOtoSettings(settings: SettingsDTOInterface): SettingsInterface {
    return {
        id: settings.id,
        comment: settings.comment,
        isSanded: settings.sanded,
        isGlued: settings.glued,
        manufacture: settings.manufacture === 0 ? ManufactureTypeEnum.Milling : ManufactureTypeEnum.Print,
        userId: settings.owner,
    } as SettingsInterface;
}
