import type OfficeInvitationDTOInterface from '@/domains/office/typescript/OfficeInvitationDTOInterface';
import type OfficeInvitationInterface from '@/domains/office/typescript/OfficeInvitationInterface';

function transformDTOtoOfficeInvitation(officeInvitation: OfficeInvitationDTOInterface): OfficeInvitationInterface {
    return {
        id: officeInvitation.id,
        office: officeInvitation.office,
        email: officeInvitation.email,
        roles: officeInvitation.roles,
        createdAt: officeInvitation.createdAt ? new Date(officeInvitation.createdAt) : undefined,
    };
}

export default transformDTOtoOfficeInvitation;
