<script setup lang="ts">
import EventBus from '@/domains/common/services/eventBus';
import TheNotificationMessage from '@/domains/common/components/TheNotificationMessage.vue';
import { onMounted, ref } from 'vue';
import type NotificationStatus from '@/domains/common/typescript/NotificationStatus';

interface MessageInterface {
    uuid?: string;
    title: string;
    status: NotificationStatus;
}

const messages = ref<Array<MessageInterface>>([]);
const TOASTER_TIME = 5_000; // 5 seconds

const hideMessage = (message: MessageInterface) => {
    messages.value = messages.value.filter((msg) => msg.uuid !== message.uuid);
};
const addMessage = (message: MessageInterface) => {
    messages.value.push(message);
    setTimeout(() => {
        hideMessage(message);
    }, TOASTER_TIME);
};
onMounted(() => {
    EventBus.on('notification-message', (message: MessageInterface) => {
        addMessage({ ...message, uuid: crypto.randomUUID() });
    });
});
</script>
<template>
    <!-- Global notification live region, render this permanently at the end of the document -->
    <div
        aria-live="assertive"
        class="pointer-events-none fixed inset-0 z-50 flex items-end px-4 py-6 sm:items-start sm:p-6"
    >
        <div v-if="messages.length" class="flex w-full flex-col items-center space-y-4 sm:items-end">
            <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
            <TransitionGroup
                enter-active-class="transform ease-out duration-500 transition"
                enter-from-class="translate-x-32 opacity-0 sm:translate-x-0 sm:translate-x-2"
                enter-to-class="translate-x-0 opacity-100 sm:translate-x-0"
                leave-active-class="transition ease-in duration-200"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <TheNotificationMessage
                    v-for="(message, index) in messages"
                    :key="index"
                    :message="$t(message.title)"
                    :status="message.status"
                    @hide-message="hideMessage(message)"
                />
            </TransitionGroup>
        </div>
    </div>
</template>
