import { formatDate } from '@vueuse/core';
import type PatientSearchFormInterface from '@/domains/patient/typescript/PatientSearchFormInterface';

function transformPatientSearchFormToString(searchForm: PatientSearchFormInterface): Record<string, string> {
    return {
        name: searchForm.name ?? '',
        lastname: searchForm.lastname ?? '',
        search: searchForm.search ?? '',
        'creationDate[after]':
            searchForm.creationDate.length > 0 ? formatDate(searchForm.creationDate[0], 'YYYY-MM-DD') : '',
        'creationDate[before]':
            searchForm.creationDate.length > 1 ? formatDate(searchForm.creationDate[1], 'YYYY-MM-DD') : '',
    };
}

export default transformPatientSearchFormToString;
