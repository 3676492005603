import ButtonColorEnum from '@/domains/common/typescript/ButtonColorEnum';

function getClassesFromColor(
    color: ButtonColorEnum,
    isDisabled: boolean,
): Record<'background' | 'text', Array<string | boolean>> {
    switch (color) {
        case ButtonColorEnum.Primary:
            return {
                background: [
                    'rounded-2xl',
                    !isDisabled && 'bg-theme-blue-500 hover:bg-theme-blue-600 active:bg-theme-blue-700',
                    isDisabled && 'bg-theme-slate-400',
                ],
                text: ['text-white px-5 py-2'],
            };
        case ButtonColorEnum.Secondary:
            return {
                background: [
                    'bg-white border-2 rounded-2xl',
                    !isDisabled &&
                        'border-theme-blue-500 text-theme-blue-500 hover:border-theme-blue-700 hover:text-theme-blue-700 active:border-theme-blue-700 active:text-theme-blue-700 active:bg-theme-blue-200',
                    isDisabled && 'border-theme-slate-400',
                ],
                text: [
                    'px-5 py-2',
                    !isDisabled && 'text-theme-blue-500 hover:text-theme-blue-700 active:text-theme-blue-700',
                    isDisabled && 'text-theme-slate-400',
                ],
            };
        case ButtonColorEnum.Menu:
            return {
                background: ['bg-transparent rounded-2xl'],
                text: [
                    'px-5 py-2',
                    !isDisabled && 'text-theme-black hover:text-theme-green-500 active:text-themeblue-500',
                    isDisabled && 'text-theme-slate-400',
                ],
            };
        case ButtonColorEnum.TablePrimary:
            return {
                background: [
                    'rounded-lg',
                    !isDisabled && 'bg-theme-green-200 hover:bg-theme-green-400',
                    isDisabled && 'bg-theme-slate-400',
                ],
                text: [!isDisabled && 'text-theme-black', isDisabled && 'text-white', 'p-2 text-sm font-bold h-8'],
            };
        case ButtonColorEnum.TableSecondary:
            return {
                background: [
                    'rounded-lg',
                    !isDisabled && 'bg-theme-blue-100 hover:bg-theme-blue-300',
                    isDisabled && 'bg-theme-slate-400',
                ],
                text: [!isDisabled && 'text-theme-black', isDisabled && 'text-white', 'p-2 text-sm font-bold h-8'],
            };
        case ButtonColorEnum.None:
        default:
            return { background: [], text: [] };
    }
}

export default getClassesFromColor;
