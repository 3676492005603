<template>
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.01324 2.92774L1.86752 13.5724H14.159L8.01324 2.92774ZM8.87926 1.42774C8.49436 0.761068 7.53211 0.761067 7.14721 1.42773L0.135467 13.5724C-0.249433 14.2391 0.23169 15.0724 1.00149 15.0724H15.025C15.7948 15.0724 16.2759 14.2391 15.891 13.5724L8.87926 1.42774Z"
            fill="currentColor"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.01324 6.05078C8.42746 6.05078 8.76324 6.38657 8.76324 6.80078V9.55151C8.76324 9.96573 8.42746 10.3015 8.01324 10.3015C7.59903 10.3015 7.26324 9.96573 7.26324 9.55151V6.80078C7.26324 6.38657 7.59903 6.05078 8.01324 6.05078Z"
            fill="currentColor"
        />
        <circle cx="8.01324" cy="11.6691" r="0.762085" fill="currentColor" />
    </svg>
</template>
