<script setup lang="ts">
import RouteNameEnum from '@/router/routeNameEnum';
import TheHeader from '@/domains/common/components/TheHeader.vue';
import TheNotification from '@/domains/common/components/TheNotification.vue';
import { computed } from 'vue';
import { RouterView, useRoute } from 'vue-router';
import '@vuepic/vue-datepicker/dist/main.css';

const route = useRoute();

const containerClass = computed(() => {
    if (
        route.name === RouteNameEnum.Login ||
        route.name === RouteNameEnum.ForgottenPassword ||
        route.name === RouteNameEnum.ResetPassword ||
        route.name === RouteNameEnum.Invite
    ) {
        return 'h-screen';
    }
    if (route.name === RouteNameEnum.Scan) {
        return 'pt-16';
    }

    return 'pt-36 pb-3 px-3';
});

const isHeaderVisible = computed(() => {
    return (
        !(route.name === RouteNameEnum.Login) &&
        !(route.name === RouteNameEnum.ForgottenPassword) &&
        !(route.name === RouteNameEnum.ResetPassword) &&
        !(route.name === RouteNameEnum.Invite)
    );
});
</script>

<template>
    <TheHeader v-if="isHeaderVisible" />
    <TheNotification />
    <div :class="containerClass">
        <RouterView />
    </div>
</template>
