<script setup lang="ts">
import BaseErrorMessage from '@/domains/common/components/BaseErrorMessage.vue';
import ColorEnum from '@/domains/common/typescript/ColorEnum';
import IconEye from '@/domains/common/components/icons/IconEye.vue';
import { estimateStrength, getPasswordStrengthClass } from '@/domains/common/services/passwordStrength';
import { Field } from 'vee-validate';
import { computed, ref, type Ref } from 'vue';

defineOptions({
    inheritAttrs: false,
});

export interface Props {
    id: string;
    placeholder?: string;
    color?: ColorEnum;
    name?: string;
    modelValue?: Ref<string | undefined> | string;
    disabled?: boolean;
    displayStrength?: boolean;
}

const colorClass: Record<ColorEnum, string> = {
    primary: 'border-transparent ',
    secondary: 'border-gray-400',
    primaryFilled: '',
};

const props = withDefaults(defineProps<Props>(), {
    color: ColorEnum.Primary,
    name: '',
    placeholder: undefined,
    modelValue: undefined,
    disabled: false,
    displayStrength: false,
});

const emits = defineEmits<{
    'update:modelValue': [value: string | null];
}>();

const shouldDisplayPassword = ref<boolean>(false);
const isInputFocused = ref<boolean>(false);
const passwordStrength = ref<number>(0);

function onInput(event: InputEvent) {
    if (event.target === null) {
        return;
    }
    const password = (event.target as HTMLTextAreaElement).value;
    emits('update:modelValue', password);
    passwordStrength.value = estimateStrength(password);
}

const passwordStrengthClass = computed<string>(() => {
    if (!props.displayStrength) {
        return 'hidden';
    }
    return getPasswordStrengthClass(passwordStrength.value);
});
</script>

<template>
    <div
        :class="[
            colorClass[props.color],
            'relative flex items-center overflow-hidden rounded-2xl border bg-white ring-2 ring-inset',
            isInputFocused && 'border-transparent ring-border',
            !isInputFocused && ' ring-transparent',
        ]"
    >
        <Field
            :id="props.id"
            :type="shouldDisplayPassword ? 'text' : 'password'"
            :placeholder="props.placeholder"
            :class="['pointer-events-auto z-0 bg-transparent focus-visible:outline-none', $attrs.class]"
            :name="props.id ?? props.name"
            :disabled="props.disabled"
            :model-value="props.modelValue"
            rows="4"
            @input="onInput"
            @focus="isInputFocused = true"
            @focusout="isInputFocused = false"
        />
        <button
            class="absolute right-0 z-50 mr-8 flex h-full cursor-pointer appearance-none items-center"
            type="button"
            @click="shouldDisplayPassword = !shouldDisplayPassword"
        >
            <IconEye
                :class="[shouldDisplayPassword && 'text-theme-green-500', !shouldDisplayPassword && 'text-theme-black']"
            />
        </button>
    </div>
    <div v-if="displayStrength" :class="['mt-2 h-1 rounded-full transition-all duration-300', passwordStrengthClass]">
        <Field
            :id="`${props.id}_strength`"
            type="hidden"
            :model-value="passwordStrength"
            :name="`${props.id}_strength`"
        />
    </div>
    <BaseErrorMessage v-if="displayStrength" :field-name="`${props.id}_strength`" />
</template>
