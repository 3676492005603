import type DeviceInterface from '@/domains/scan/typescript/interfaces/DeviceInterface';
import type HelloInterface from '@/domains/scan/typescript/interfaces/HelloInterface';
import type MessageInterface from '@/domains/scan/typescript/interfaces/MessageInterface';
import type ScanInterface from '@/domains/scan/typescript/interfaces/ScanInterface';
import type ScanStatusInterface from '@/domains/scan/typescript/interfaces/ScanStatusInterface';

const SCAN_API_URI = import.meta.env.VITE_SCAN_API_URI;

const hello = async (): Promise<HelloInterface> => {
    const response = await fetch(`${SCAN_API_URI}/hello`);

    return response.json();
};

const getListDevices = async (): Promise<DeviceInterface[]> => {
    const response = await fetch(`${SCAN_API_URI}/devices`);

    return response.json();
};

const getDeviceInfo = async (deviceId: string): Promise<DeviceInterface | MessageInterface> => {
    const encodedId = encodeURI(deviceId);
    const response = await fetch(`${SCAN_API_URI}/devices/${encodedId}`);

    return response.json();
};

const askNewScan = async (
    deviceId: string,
    {
        backend,
        brightness,
        contrast,
        lightSensibility,
    }: { backend: string; brightness?: number; contrast?: number; lightSensibility?: string },
): Promise<ScanInterface | MessageInterface> => {
    const encodedId = encodeURI(deviceId);

    const requestOptions: RequestInit = {
        method: 'POST',
    };

    const options: Record<string, number | string> = {};

    if (backend === 'wia') {
        options['Horizontal Resolution'] = 150;
        options['Vertical Resolution'] = 150;

        if (brightness) {
            options.Brightness = brightness;
        }

        if (contrast) {
            options.Contrast = contrast;
        }
    }

    if (backend === 'scanline') {
        options.resolution = 150;
    }

    if (backend === 'fusion3d') {
        if (lightSensibility) {
            options.sensitivity = lightSensibility;
        }
    }

    if (Object.values(options).length > 0) {
        requestOptions.headers = {
            'Content-Type': 'application/json',
        };
        requestOptions.body = JSON.stringify(options);
    }
    const response = await fetch(`${SCAN_API_URI}/devices/${encodedId}/new-scan`, requestOptions);

    return response.json();
};

const getListScans = async (): Promise<Array<string>> => {
    const response = await fetch(`${SCAN_API_URI}/scans`);

    return response.json();
};

const getScanStatus = async (scanId: string): Promise<ScanStatusInterface | MessageInterface> => {
    const response = await fetch(`${SCAN_API_URI}/scans/${scanId}`);

    return response.json();
};

const getScanFiles = async (scanId: string): Promise<Array<string> | MessageInterface> => {
    const response = await fetch(`${SCAN_API_URI}/scans/${scanId}/files`);

    return response.json();
};

const getScanFile = async (params: { scanId: string; fileId: string }): Promise<Blob | MessageInterface> => {
    const response = await fetch(`${SCAN_API_URI}/scans/${params.scanId}/files/${params.fileId}`);
    const headersContentType = response.headers.get('Content-type');
    if (headersContentType === 'application/json') {
        return response.json();
    }

    return response.blob();
};

const deleteScan = async (scanId: string): Promise<MessageInterface> => {
    const response = await fetch(`${SCAN_API_URI}/scans/${scanId}`, {
        method: 'DELETE',
    });

    return response.json();
};

export default {
    hello,
    getListDevices,
    getDeviceInfo,
    askNewScan,
    getListScans,
    getScanStatus,
    getScanFiles,
    getScanFile,
    deleteScan,
};
