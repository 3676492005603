<script setup lang="ts">
import { logout } from '@/domains/authentication/services/authenticate';
import BaseButton from '@/domains/common/components/BaseButton.vue';
import BaseButtonRouterLink from '@/domains/common/components/BaseButtonRouterLink.vue';
import eventBus from '@/domains/common/services/eventBus';
import ButtonColorEnum from '@/domains/common/typescript/ButtonColorEnum';
import NotificationStatus from '@/domains/common/typescript/NotificationStatus';
import AuthenticationLayout from '@/layouts/AuthenticationLayout.vue';
import RegistrationForm from '@/domains/authentication/components/RegistrationForm.vue';
import RouteNameEnum from '@/router/routeNameEnum';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const isReadyToRender = ref(false);
const shouldDisplayRegistrationForm = ref(false);

onMounted(async () => {
    if (!route.params.token) {
        eventBus.emit('notification-message', {
            title: 'invitation.invalid_token',
            status: NotificationStatus.error,
        });
    }
    await logout({ shouldLoginRedirect: false });
    isReadyToRender.value = true;
});
</script>

<template>
    <AuthenticationLayout>
        <div
            v-if="isReadyToRender && !shouldDisplayRegistrationForm"
            class="mx-auto flex w-full max-w-sm flex-col justify-center gap-10 lg:w-96"
        >
            <h1 class="text-center text-3xl uppercase">
                {{ $t('invitation.title_choice') }}
            </h1>
            <div class="flex flex-col items-center justify-center gap-8">
                <BaseButtonRouterLink
                    :to="{ name: RouteNameEnum.Login, params: { token: route.params.token } }"
                    class="w-full"
                    :color="ButtonColorEnum.Secondary"
                    :label="$t('invitation.button_login')"
                />
                <BaseButton
                    class="w-full"
                    :color="ButtonColorEnum.Primary"
                    :label="$t('invitation.button_register')"
                    @click="shouldDisplayRegistrationForm = true"
                />
            </div>
        </div>
        <div
            v-if="shouldDisplayRegistrationForm"
            class="mx-auto flex w-full max-w-sm flex-col justify-center gap-10 lg:w-96"
        >
            <h1 class="text-center text-3xl uppercase">
                {{ $t('invitation.form_register.title') }}
            </h1>
            <RegistrationForm @cancel="shouldDisplayRegistrationForm = false" />
        </div>
    </AuthenticationLayout>
</template>
