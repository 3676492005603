import arrayToSelectOption from '@/domains/common/services/utils/arrayToSelectOption';
import createNumberArray from '@/domains/common/services/utils/createNumberArray';
import type ParamsCorrectionInterface from '@/domains/scan/typescript/interfaces/ParamsCorrectionInterface';
import type ParamsOutputInterface from '@/domains/scan/typescript/interfaces/ParamsOutputInterface';
import type SelectOptionInterface from '@/domains/common/typescript/SelectOptionInterface';

const paramToInt = (key: keyof ParamsCorrectionInterface, param: ParamsCorrectionInterface): number => {
    if (typeof param[key] === 'string') {
        const paramToFloat = (param[key] as string).replace(',', '.');
        const number = parseInt(paramToFloat as string, 10) as number;

        return number;
    }

    return param[key] as number;
};

const paramsToSelect = (params: ParamsCorrectionInterface): ParamsOutputInterface => {
    const defaultBase = paramToInt('default', params);
    const safeObject: {
        label: string;
        min: number;
        max: number;
        step: number;
        default?: { id: number; name: number };
        selectOptions: Array<SelectOptionInterface<string>>;
    } = {
        label: params.label,
        min: paramToInt('min', params),
        max: paramToInt('max', params),
        step: paramToInt('step', params),
        selectOptions: arrayToSelectOption(
            createNumberArray({
                start: paramToInt('min', params),
                stop: paramToInt('max', params),
                step: paramToInt('step', params),
            }),
        ),
    };

    return {
        ...safeObject,
        ...{
            default: {
                id: `${defaultBase}`,
                name: `${defaultBase}`,
            },
        },
    };
};

const computeSelectOptionsfromJson = (json: ParamsCorrectionInterface[]): Record<string, ParamsOutputInterface> => {
    const jsonParsed = JSON.parse(JSON.stringify(json));
    jsonParsed.unshift({}); // escape the first element to avoid acc to be not treated
    return jsonParsed.reduce((acc: ParamsOutputInterface[], param: ParamsCorrectionInterface) => {
        return {
            ...acc,
            ...{ [param.name]: paramsToSelect(param) },
        };
    });
};

export default computeSelectOptionsfromJson;
