import transformDTOtoOffice from '@/domains/admin/transformers/transformDTOtoOffice';
import HydraAPI from '@/domains/common/services/hydraApi';
import transformDTOtoUserProfile from '@/domains/authentication/transformers/transformDTOtoUserProfile';
import type AdminOfficeInterface from '@/domains/admin/typescript/AdminOfficeInterface';
import type AdminOfficeDTOCollectionInterface from '@/domains/admin/typescript/AdminOfficeDTOCollectionInterface';
import type AdminOfficeDTOInterface from '@/domains/admin/typescript/AdminOfficeDTOInterface';
import type AdminOfficeEditDTOInterface from '@/domains/admin/typescript/AdminOfficeEditDTOInterface';
import type AdminOfficeUserEditDTOInterface from '@/domains/admin/typescript/AdminOfficeUserEditDTOInterface';
import type UserProfileDTOInterface from '@/domains/authentication/typescript/UserProfileDTOInterface';
import type UserProfileInterface from '@/domains/authentication/typescript/UserProfileInterface';
import type PaginatedEntitiesInterface from '@/domains/common/typescript/PaginatedEntitiesInterface';

const getOffices = async (
    urlSearchParams?: URLSearchParams,
): Promise<PaginatedEntitiesInterface<AdminOfficeInterface>> => {
    const url = !urlSearchParams ? '/offices' : `/offices?${urlSearchParams.toString()}`;
    const data = await HydraAPI.getJson<AdminOfficeDTOCollectionInterface>(url);
    const offices = data['hydra:member'].map((officeDTO) => transformDTOtoOffice(officeDTO));
    return { entities: offices, total: data['hydra:totalItems'] };
};

const getOffice = async (id: AdminOfficeInterface['id']): Promise<AdminOfficeInterface> => {
    const url = `/offices/${id}`;
    const data = await HydraAPI.getJson<AdminOfficeDTOInterface>(url);
    return transformDTOtoOffice(data);
};

const createOffice = async (officeForm: AdminOfficeEditDTOInterface): Promise<AdminOfficeInterface> => {
    const url = '/offices';
    const body = JSON.stringify(officeForm);
    const data = await HydraAPI.postJson<AdminOfficeDTOInterface>(url, { body });

    return transformDTOtoOffice(data);
};

const updateOffice = async (
    officeForm: AdminOfficeEditDTOInterface,
    id: AdminOfficeInterface['id'],
): Promise<AdminOfficeInterface> => {
    const url = `/offices/${id}`;
    const body = JSON.stringify(officeForm);
    const data = await HydraAPI.putJson<AdminOfficeDTOInterface>(url, { body });

    return transformDTOtoOffice(data);
};

const createOfficeHolder = async (officeUser: AdminOfficeUserEditDTOInterface): Promise<UserProfileInterface> => {
    const url = '/office_users';
    const body = JSON.stringify(officeUser);
    const data = await HydraAPI.postJson<UserProfileDTOInterface>(url, { body });

    return transformDTOtoUserProfile(data);
};

const updateOfficeHolder = async (
    officeUser: AdminOfficeUserEditDTOInterface,
    id: UserProfileDTOInterface['id'],
): Promise<UserProfileInterface> => {
    const url = `/office_users/${id}`;
    const body = JSON.stringify(officeUser);
    const data = await HydraAPI.putJson<UserProfileDTOInterface>(url, { body });

    return transformDTOtoUserProfile(data);
};

export default { getOffices, getOffice, createOffice, updateOffice, createOfficeHolder, updateOfficeHolder };
