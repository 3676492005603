<script setup lang="ts">
import ButtonColorEnum from '@/domains/common/typescript/ButtonColorEnum';
import getClassesFromColor from '@/domains/common/services/getBaseButtonColor';
import { computed } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

interface Props {
    icon?: Object;
    to: RouteLocationRaw;
    label: string;
    showLabel?: boolean;
    disabled?: boolean;
    defaultClasses?: boolean;
    color?: ButtonColorEnum;
}

const props = withDefaults(defineProps<Props>(), {
    icon: undefined,
    showLabel: true,
    disabled: false,
    defaultClasses: true,
    color: ButtonColorEnum.None,
});

const colorClasses = computed(() => getClassesFromColor(props.color, props.disabled));
</script>

<template>
    <RouterLink
        as="button"
        :to="to"
        :class="[
            defaultClasses && 'flex cursor-pointer items-center justify-center font-semibold',
            $attrs.class,
            colorClasses.background,
            colorClasses.text,
        ]"
    >
        <component :is="icon" v-if="icon !== undefined" />
        <span :class="[icon && 'ml-2', !showLabel && 'sr-only']">{{ label }}</span>
    </RouterLink>
</template>
