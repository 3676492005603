import i18n from '@/translations';
import { number, object, ref, string } from 'yup';

const { t } = i18n.global;

const registrationSchema = object({
    password: string().required(t('global.form_errors.required')),
    password_strength: number().min(1, t('invitation.form_register.validation.password_strength')),
    confirm_password: string()
        .required(t('global.form_errors.required'))
        .oneOf([ref('password')], t('invitation.form_register.validation.password_mismatch')),
});

export default registrationSchema;
