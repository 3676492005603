import type PodonexConfigurationDTOInterface from '../typescript/interfaces/PodonexConfigurationDTOInterface';
import type PodonexConfigurationInterface from '../typescript/interfaces/PodonexConfigurationInterface';

export default function transformDTOtoPodonexConfiguration(
    configuration: PodonexConfigurationDTOInterface,
): PodonexConfigurationInterface {
    return {
        id: configuration.id,
        scannerId: configuration.scanner,
        lightSensibility: configuration.lightSensibility,
        brightness: configuration.brightness,
        contrast: configuration.contrast,
        sobelThreshold: configuration.threshold,
        cleaningSteps: configuration.steps,
        userId: configuration.owner,
    } as PodonexConfigurationInterface;
}
