import { ControlsOperation, type TControlMouse, type ControlKey } from './ICameraControls';
import { EventDispatcher, PerspectiveCamera, Quaternion, Vector2, Vector3, type Camera } from 'three';
import type ICameraControls from './ICameraControls';

// events
const _changeEvent = { type: 'change' };
const _startEvent = { type: 'start' };
const _endEvent = { type: 'end' };

class CustomTrackballControls extends EventDispatcher implements ICameraControls {
    // Public properties
    public domElement: HTMLElement;
    public camera: Camera;
    public enabled: boolean;
    public mouseActions: Array<any>;
    public rotateSpeed: number;
    public zoomSpeed: number;
    public panSpeed: number;
    public enableZoom: boolean;
    public enableRotate: boolean;
    public enablePan: boolean;
    public target: Vector3;
    public maxDistance: number;
    public minDistance: number;
    public enableDamping: boolean;
    public dampingFactor: number;
    // Private properties
    private _prevTime: number;
    private _rotAxis: Vector3;
    private _rotAmount: number;
    private _prevPos: Vector3;
    private _mouseStartPosition: Vector2;
    private _mouseStartSpherePosition: Vector3;
    private _targetStartPosition: Vector3;
    private _cameraStartPosition: Vector3;
    private _cameraStartRotation: Quaternion;
    private _currentOperation: ControlsOperation;
    private _isInOperation: boolean;

    constructor(camera: Camera, domElement: HTMLElement) {
        super();
        this.domElement = domElement;
        this.camera = camera;
        this.enabled = true;
        this.mouseActions = [];
        this._mouseStartPosition = new Vector2();
        this._mouseStartSpherePosition = new Vector3();
        this._targetStartPosition = new Vector3();
        this._cameraStartPosition = new Vector3();
        this._cameraStartRotation = new Quaternion();
        this._currentOperation = ControlsOperation.ROTATE;
        this._isInOperation = false;

        this.rotateSpeed = 1;
        this.zoomSpeed = 0.1;
        this.enablePan = true;
        this.panSpeed = 0.002;
        this.enableRotate = true;
        this.enableZoom = true;
        this.target = new Vector3();
        this.minDistance = 0;
        this.maxDistance = Infinity;

        this.enableDamping = false;
        this.dampingFactor = 0.95;
        this._prevTime = 0;
        this._prevPos = new Vector3();
        this._rotAxis = new Vector3();
        this._rotAmount = 0;

        this.domElement.addEventListener('contextmenu', this.#onContextMenuCallback);
        this.domElement.addEventListener('wheel', this.#onWheelCallback);
        this.domElement.addEventListener('pointerdown', this.#onPointerDownCallback);
    }

    update(): unknown {
        if (this.enabled && this.enableDamping && this._rotAmount > 0 && this.enableRotate) {
            const finalRot = new Quaternion().setFromAxisAngle(this._rotAxis, this._rotAmount);
            // Update the camera's position by applying the rotation around the target
            const offset = this.camera.position.clone().sub(this.target); // Get the offset from the target
            offset.applyQuaternion(finalRot); // Rotate the offset
            this.camera.position.copy(this.target).add(offset); // Set the new position

            // Update the camera's quaternion to include the rotation
            this.camera.quaternion.multiplyQuaternions(finalRot, this.camera.quaternion);
            this.dispatchEvent(_changeEvent);

            this._rotAmount *= this.dampingFactor;
            if (this._rotAmount < 0.001) {
                this._rotAmount = 0;
                this._isInOperation = false;
                this.dispatchEvent(_endEvent);
            }
        }
        return this.enabled;
    }

    /**
     * Set a new mouse action by specifying the operation to be performed and a mouse/key combination. In case of conflict, replaces the existing one
     * @param {String} operation The operation to be performed ('PAN', 'ROTATE', 'ZOOM', 'FOV)
     * @param {*} mouse A mouse button (0, 1, 2) or 'WHEEL' for wheel notches
     * @param {*} key The keyboard modifier ('CTRL', 'SHIFT') or null if key is not needed
     */
    addMouseAction(operation: ControlsOperation, mouse: TControlMouse, key?: ControlKey | undefined): void {
        let state;
        if (mouse === 'WHEEL') {
            if (operation !== ControlsOperation.ZOOM) {
                // cannot associate 2D operation to 1D input
                return;
            }
        }
        switch (operation) {
            case 'PAN':
                state = ControlsOperation.PAN;
                break;
            case 'ROTATE':
                state = ControlsOperation.ROTATE;
                break;
            default:
                state = ControlsOperation.ZOOM;
                break;
        }
        const action = {
            operation,
            mouse,
            key,
            state,
        };
        for (let i = 0; i < this.mouseActions.length; i++) {
            if (this.mouseActions[i].mouse === action.mouse && this.mouseActions[i].key === action.key) {
                this.mouseActions.splice(i, 1, action);
                return;
            }
        }
        this.mouseActions.push(action);
    }

    /**
     * Remove a mouse action by specifying its mouse/key combination
     * @param {*} mouse A mouse button (0, 1, 2) or 'WHEEL' for wheel notches
     * @param {*} key The keyboard modifier ('CTRL', 'SHIFT') or null if key is not needed
     * @returns {Boolean} True if the operation has been succesfully removed, false otherwise
     */
    removeMouseAction(mouse: TControlMouse, key?: ControlKey | undefined): boolean {
        for (let i = 0; i < this.mouseActions.length; i++) {
            if (this.mouseActions[i].mouse === mouse && this.mouseActions[i].key === key) {
                this.mouseActions.splice(i, 1);
                return true;
            }
        }
        return false;
    }

    /**
     * Return the operation associated to a mouse/keyboard combination
     * @param {number | string} mouse A mouse button (0, 1, 2) or 'WHEEL' for wheel notches
     * @param {string | null} key The keyboard modifier ('CTRL', 'SHIFT') or null if key is not needed
     * @returns The operation if it has been found, null otherwise
     */
    getOpFromAction(mouse: number | string, key: string | null): ControlsOperation | null {
        let action;

        for (let i = 0; i < this.mouseActions.length; i++) {
            action = this.mouseActions[i];
            // eslint-disable-next-line eqeqeq
            if (action.mouse === mouse && action.key == key) {
                return action.operation;
            }
        }

        if (key != null) {
            for (let i = 0; i < this.mouseActions.length; i++) {
                action = this.mouseActions[i];
                if (action.mouse === mouse && action.key == null) {
                    return action.operation;
                }
            }
        }

        return null;
    }

    operationStart(event: MouseEvent) {
        if (this.enabled) {
            if (this._rotAmount > 0) {
                this._rotAmount = 0;
                this.dispatchEvent(_endEvent);
            }
            this._isInOperation = true;
            this.dispatchEvent(_startEvent);
            this._mouseStartPosition = new Vector2(event.clientX, event.clientY).multiplyScalar(
                window.devicePixelRatio,
            );
            this._targetStartPosition = this.target.clone();
            this._cameraStartPosition = this.camera.position.clone();
            if (this._currentOperation === ControlsOperation.ROTATE) {
                this._mouseStartSpherePosition = CustomTrackballControls.mapScreenToSphere(
                    this._mouseStartPosition,
                ).applyQuaternion(this.camera.quaternion);
                this._cameraStartRotation = this.camera.quaternion.clone();
            }
        }
    }

    operationMove(event: MouseEvent) {
        if (this.enabled) {
            const mouseCurrentPosition = new Vector2(event.clientX, event.clientY).multiplyScalar(
                window.devicePixelRatio,
            );
            switch (this._currentOperation) {
                case ControlsOperation.PAN:
                    if (this.enablePan) {
                        // Pan amount is scaled with distance to target
                        const distanceToTarget = this.camera.position.distanceTo(this.target);
                        const posDiff = mouseCurrentPosition
                            .sub(this._mouseStartPosition)
                            .multiplyScalar(
                                (this.panSpeed / 80) * distanceToTarget * (this.camera as PerspectiveCamera).fov ?? 1,
                            );
                        const convDiff = new Vector3(-posDiff.x, posDiff.y, 0).applyQuaternion(this.camera.quaternion);
                        this.target.copy(convDiff.clone().add(this._targetStartPosition));
                        this.camera.position.copy(convDiff.add(this._cameraStartPosition));
                    }
                    break;
                case ControlsOperation.ROTATE:
                    if (this.enableRotate) {
                        const endPos = CustomTrackballControls.mapScreenToSphere(mouseCurrentPosition).applyQuaternion(
                            this._cameraStartRotation,
                        );

                        // Compute the vector perpendicular to the begin and end vectors
                        const rotAxis = new Vector3().crossVectors(this._mouseStartSpherePosition, endPos);
                        let rotQuat;
                        if (rotAxis.lengthSq() > 0.000001) {
                            rotQuat = new Quaternion().setFromAxisAngle(
                                rotAxis.normalize(),
                                this._mouseStartSpherePosition.angleTo(endPos) * this.rotateSpeed * 5,
                            );
                        } else {
                            rotQuat = new Quaternion(); // Identity quaternion (no rotation)
                        }
                        rotQuat.invert();

                        if (this.enableDamping) {
                            this._prevTime = performance.now();
                            this._prevPos = this.camera.position.clone().sub(this.target);
                        }

                        const targetToCamera = this._cameraStartPosition
                            .clone()
                            .sub(this._targetStartPosition)
                            .applyQuaternion(rotQuat);
                        this.camera.position.copy(targetToCamera.add(this.target));
                        this.camera.quaternion.copy(rotQuat.multiply(this._cameraStartRotation));
                    }
                    break;
                default:
                    if (this.enableZoom) {
                        const zoomVector = this.computeZoomVector(
                            mouseCurrentPosition.y - this._mouseStartPosition.y,
                            this._cameraStartPosition,
                            this._targetStartPosition,
                        );
                        this.camera.position.copy(zoomVector.add(this._cameraStartPosition));
                    }
                    break;
            }
            this.dispatchEvent(_changeEvent);
        }
    }

    operationEnd() {
        if (this.enableDamping && this._currentOperation === ControlsOperation.ROTATE && this.enableRotate) {
            const timeSinceRelease = performance.now() - this._prevTime;
            if (timeSinceRelease < 1) {
                const currentPos = this.camera.position.clone().sub(this.target);
                this._rotAxis.crossVectors(this._prevPos, currentPos).normalize();
                this._rotAmount = this._prevPos.angleTo(currentPos);
                return;
            }
        }

        this._isInOperation = false;
        this.dispatchEvent(_endEvent);
    }

    #onContextMenuCallback = (event: MouseEvent) => {
        if (!this.enabled) {
            return;
        }
        for (let i = 0; i < this.mouseActions.length; i++) {
            if (this.mouseActions[i].mouse === 2) {
                // prevent only if button 2 is actually used
                event.preventDefault();
                break;
            }
        }
    };

    #onPointerDownCallback = (event: MouseEvent) => {
        let modifier = null;

        if (event.ctrlKey || event.metaKey) {
            modifier = 'CTRL';
        } else if (event.shiftKey) {
            modifier = 'SHIFT';
        }

        const mouseOp = this.getOpFromAction(event.button, modifier);
        if (mouseOp) {
            this._currentOperation = mouseOp;
            window.addEventListener('pointermove', this.#onPointerMoveCallback);
            window.addEventListener('pointerup', this.#onPointerUpCallback);

            this.operationStart(event);
        }
    };

    #onPointerUpCallback = () => {
        window.removeEventListener('pointermove', this.#onPointerMoveCallback);
        window.removeEventListener('pointerup', this.#onPointerUpCallback);
        this.operationEnd();
    };

    #onPointerMoveCallback = (event: PointerEvent) => {
        this.operationMove(event);
    };

    #onWheelCallback = (event: WheelEvent) => {
        if (this.enabled && this.enableZoom) {
            let modifier = null;

            if (event.ctrlKey || event.metaKey) {
                modifier = 'CTRL';
            } else if (event.shiftKey) {
                modifier = 'SHIFT';
            }

            const mouseOp = this.getOpFromAction('WHEEL', modifier);

            if (mouseOp != null) {
                event.preventDefault();
                this.dispatchEvent(_startEvent);
                if (this._isInOperation) {
                    // Adapt initial camera position while in another operation
                    this._cameraStartPosition.add(
                        this.computeZoomVector(event.deltaY, this._cameraStartPosition, this._targetStartPosition),
                    );
                }
                this.camera.position.add(this.computeZoomVector(event.deltaY));

                this.dispatchEvent(_changeEvent);
                this.dispatchEvent(_endEvent);
            }
        }
    };

    private static mapScreenToSphere(screenPosition: Vector2) {
        const windowSize = new Vector2(window.innerWidth, window.innerHeight).multiplyScalar(window.devicePixelRatio);

        const longSide = Math.max(windowSize.x, windowSize.y);
        const normMouse = new Vector2(
            (screenPosition.x - windowSize.x / 2) / (longSide / 2),
            -(screenPosition.y - windowSize.y / 2) / (longSide / 2),
        );

        const quadrance = normMouse.lengthSq();
        if (quadrance > 1) {
            // The point is outside the sphere, we place it to the border at z = 0
            const norm = 1 / Math.sqrt(quadrance);
            return new Vector3(normMouse.x * norm, normMouse.y * norm, 0);
        }
        return new Vector3(normMouse.x, normMouse.y, Math.sqrt(1 - quadrance));
    }

    private computeZoomVector(
        zoomDelta: number,
        cameraPos: Vector3 = this.camera.position,
        targetPos: Vector3 = this.target,
    ): Vector3 {
        const notchDeltaY = 12500; // distance of one notch of mouse wheel

        const zoomVariation =
            -this.zoomSpeed * (zoomDelta / notchDeltaY) * (80 / (this.camera as PerspectiveCamera).fov ?? 80);

        const dir = targetPos.clone().sub(cameraPos).normalize();
        const diffPos = dir.clone().multiplyScalar(zoomVariation);

        const diffMax = targetPos.clone().sub(dir.clone().multiplyScalar(this.maxDistance)).sub(cameraPos);
        const diffMin = targetPos.clone().sub(dir.clone().multiplyScalar(this.minDistance)).sub(cameraPos);
        if (zoomVariation > 0 && diffPos.lengthSq() > diffMin.lengthSq()) {
            return diffMin;
        }
        if (zoomVariation < 0 && diffPos.lengthSq() > diffMax.lengthSq()) {
            return diffMax;
        }
        return diffPos;
    }

    /**
     * Remove all listeners, stop animations and clean scene
     */
    dispose(): void {
        this.domElement.removeEventListener('pointerdown', this.#onPointerDownCallback);
        this.domElement.removeEventListener('wheel', this.#onWheelCallback);
        this.domElement.removeEventListener('contextmenu', this.#onContextMenuCallback);

        window.removeEventListener('pointermove', this.#onPointerMoveCallback);
        window.removeEventListener('pointerup', this.#onPointerUpCallback);
    }
}

export default CustomTrackballControls;
