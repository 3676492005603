import Gizmo from './Gizmo';
import ScanController from '@/3d-app/scan/ScanController';
import { Mesh, MeshBasicMaterial, PlaneGeometry, TextureLoader, Vector2, Vector3 } from 'three';
import type ElementMesh from '@/3d-app/scan/elementPlacement/meshes/ElementMesh';

/**
 * Gizmo that shows a circle, and is used to set scale from a border
 * The border is specified on construction
 */
class ScaleGizmo extends Gizmo {
    private _texturedSquare: Mesh;
    private _highlightMaterial: MeshBasicMaterial;
    private _origin: Vector2;

    /**
     * Gizmo constructor
     * @param transformFunction function that will be called when the gizmo is used
     * @param origin 2D vector that indicated the position of the gizmo on mesh (factor of mesh scale).
     * Ex: Top right corner will be Vector2(1, 1), middle left will be Vector2(-1, 0)
     */
    public constructor(transformFunction: (pos: Vector3) => void, origin: Vector2) {
        super(transformFunction);

        // Load texture
        const texture = new TextureLoader().load('/images/scale-gizmo.png');

        // Mesh build
        this._highlightMaterial = new MeshBasicMaterial({
            color: 0xffaaaa,
            transparent: true,
            opacity: 0.65,
            depthWrite: false,
            depthTest: false,
            map: texture,
        });

        this._origin = origin;

        this._texturedSquare = new Mesh(new PlaneGeometry(0.8, 0.8), this._highlightMaterial);
        this._texturedSquare.layers.enable(3);

        this.add(this._texturedSquare);

        this.autoRescaleElement(this._texturedSquare);
    }

    public setHighlighted(highlighted: boolean): void {
        super.setHighlighted(highlighted);
        this._highlightMaterial.opacity = highlighted ? 1.0 : 0.65;
    }

    public update(attachedMesh: ElementMesh): void {
        // Update position and scale (compensation for base mesh deform)
        const bbSize = new Vector3(0, 0, 0);
        const offset = 0;

        attachedMesh.boundingBox.getSize(bbSize);

        const zPos = 0.2 * ScanController.SCALE_TO_M_FACTOR + bbSize.z / 2; // Z position to set order of gizmos

        this._texturedSquare.scale.copy(new Vector3(1, 1, 1).divide(attachedMesh.scale));
        this._texturedSquare.position.set(
            this._origin.x * (bbSize.x / 2 + offset / attachedMesh.scale.x),
            this._origin.y * (bbSize.y / 2 + offset / attachedMesh.scale.y),
            zPos,
        );
    }
}

export default ScaleGizmo;
