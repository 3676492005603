import type DeviceInterface from '@/domains/scan/typescript/interfaces/DeviceInterface';
import type MessageInterface from '@/domains/scan/typescript/interfaces/MessageInterface';

function isMessage(scanDevice: MessageInterface | DeviceInterface | undefined): scanDevice is MessageInterface {
    return (scanDevice as MessageInterface).message !== undefined;
}

function isDevice(scanDevice: MessageInterface | DeviceInterface | undefined): scanDevice is DeviceInterface {
    return (scanDevice as DeviceInterface).deviceId !== undefined;
}

export { isMessage, isDevice };
