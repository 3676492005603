import type TLoopInfo from './TLoopInfo';
import type TMainLoopParams from './TMainLoopParams';

/**
 * A loop...
 * Can be used to render 2d or 3d, or to do anything at a rather fixed time interval
 * It's using RequestAnimationFrame, so to get a constant time interval,
 * 60 must be a multiple of your desired fps
 */
class MainLoop {
    private _callbacks: Array<(loopInfo: TLoopInfo) => void> = [];

    private _fps: number;

    private _activeFps: number = -1;

    private _idleFps: number = 1;

    private _interval: number = 0;

    private _currentRequestId: number | null = null;

    private _lastLoopCorrectedTime: number = window.performance.now();

    private _lastLoopTime: number = window.performance.now();

    private _idle: boolean = false;

    private _enabled: boolean = false;

    private _debug: boolean = false;

    /**
     * @constructor
     */
    constructor(params?: TMainLoopParams) {
        this._activeFps = params?.activeFps || 60;
        this._idleFps = params?.idleFps || 1;

        this._debug = params?.debug || false;

        this._fps = this._activeFps;

        // Initialization
        this.initListeners();
        this.refreshIntervalValue();
        if (this._debug) {
            this.initDebug();
        }
    }

    /**
     * Refresh the wanted time interval between callbacks
     */
    refreshIntervalValue() {
        const epsilon = 0.01;
        if (this._idle) {
            if (this._idleFps > 0) {
                this._interval = 1000 / this._idleFps - epsilon;
            } else if (this._idleFps === 0) {
                if (this._currentRequestId) {
                    window.cancelAnimationFrame(this._currentRequestId);
                }
            } else {
                this._interval = -1;
            }
        } else if (this._activeFps > 0) {
            this._interval = 1000 / this._activeFps - epsilon;
        } else if (this._activeFps === 0) {
            if (this._currentRequestId) {
                window.cancelAnimationFrame(this._currentRequestId);
            }
        } else {
            this._interval = -1;
        }
    }

    /**
     * Launch window listeners : lost of focus management
     */
    initListeners() {
        window.addEventListener('focus', this.onWindowFocus.bind(this));
        window.addEventListener('blur', this.onWindowBlur.bind(this));
    }

    removeListeners(): void {
        window.removeEventListener('focus', this.onWindowFocus);
        window.removeEventListener('blur', this.onWindowBlur);
    }

    onWindowFocus() {
        if (this._currentRequestId) {
            window.cancelAnimationFrame(this._currentRequestId);
        }
        if (this.enabled) {
            this.loop();
        }
        this.idle = false;
    }

    onWindowBlur() {
        this.idle = true;
    }

    /**
     * Add Debug callback to log loop info
     */
    initDebug() {
        this.addCallback((loopInfo: TLoopInfo): void => {
            console.log('LoopInfos', JSON.stringify(loopInfo));
        });
    }

    /**
     * Start the loop.
     */
    start() {
        this._enabled = true;
        if (this._currentRequestId) {
            window.cancelAnimationFrame(this._currentRequestId);
        }
        if (!(this.idle && this._idleFps === 0)) {
            this.loop();
        }
    }

    /**
     * Stop the loop.
     */
    stop() {
        this._enabled = false;
        if (this._currentRequestId) {
            window.cancelAnimationFrame(this._currentRequestId);
        }
    }

    /**
     * Add a callback.
     * @param {Function} callback
     */
    addCallback(callback: (loopInfo: TLoopInfo) => void) {
        this._callbacks.push(callback);
    }

    /**
     * Remove a callback.
     * @param {Function} callback
     */
    removeCallback(callback: () => void) {
        const index = this._callbacks.indexOf(callback);
        if (index !== -1) {
            this._callbacks.splice(index, 1);
        }
    }

    /**
     * The loop
     * Inspired by
     * https://stackoverflow.com/questions/19764018/controlling-fps-with-requestanimationframe
     * @method $data.loop
     * @private
     * @param {Number} timestamp
     */
    loop(now = this._lastLoopTime) {
        // Request animation frame => $data.loop executed every screen refresh
        this._currentRequestId = requestAnimationFrame((t) => this.loop(t));

        // No limitation, the loop goes as fast as the screen refresh rate (if it can)
        if (this._interval === -1) {
            this.update(now);
        } else {
            // Fps throttling :
            // We execute the callbacks only if enough time has passed
            const correctedTimeSinceLastCall = now - this._lastLoopCorrectedTime;
            if (correctedTimeSinceLastCall >= this._interval) {
                // Get ready for next frame by setting lastTime=now, but...
                // Also, adjust for interval not being multiple of 16.67
                this._lastLoopCorrectedTime = now - (correctedTimeSinceLastCall % this._interval);
                this.update(now);
            }
        }
    }

    /**
     * Update function called in the loop :
     * - refresh fps and deltaTime values
     * - call the callback functions
     * - emit the update events
     * @param  {Object} loopInfo loop informations transmitted to the callbacks and by the event
     */
    update(now: number) {
        //  Fps
        const timeSinceLastCall = now - this._lastLoopTime;
        this._fps = 1000 / timeSinceLastCall;
        this._lastLoopTime = now;
        const loopInfo = {
            time: now,
            timeSecond: now * 0.001,
            timeSinceLastCall,
            fps: this._fps,
            idle: this.idle,
        };

        // loop events
        // self.app.events.emit("update", loopInfo);

        // loop callbacks
        for (let i = 0; i < this._callbacks.length; i++) {
            try {
                this._callbacks[i](loopInfo);
            } catch (error) {
                console.error(error);
            }
        }
    }

    // -- GETTERS SETTERS ---
    /**
     * Fps when the app is considered as active
     * To get a constant time interval between frames,
     * 60 must be a multiple of the fps
     * @param  {Number} activeFps
     */
    set activeFps(activeFps) {
        this._activeFps = activeFps;
        this.refreshIntervalValue();
    }

    get activeFps() {
        return this._activeFps;
    }

    /**
     * Fps when the app is considered as idle (no focus on windows)
     * @param  {Number} idleFps
     */
    set idleFps(idleFps) {
        this._idleFps = idleFps;
        this.refreshIntervalValue();
    }

    get idleFps() {
        return this._idleFps;
    }

    /**
     * Is the app considered as idle
     * @param  {Boolean} isIdle
     */
    set idle(isIdle) {
        this._idle = isIdle;
        this.refreshIntervalValue();
    }

    get idle() {
        return this._idle;
    }

    /**
     * Is the loop running
     * @return {Boolean}
     */
    get enabled() {
        return this._enabled;
    }
    //---------------------

    dispose() {
        this.stop();
        this._callbacks = [];
        this.removeListeners();
    }
}
export default MainLoop;
