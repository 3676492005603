import i18n from '@/translations';
import ManufactureTypeEnum from '@/domains/scan/typescript/enums/ManufactureTypeEnum';
import { object, string, boolean } from 'yup';

const { t } = i18n.global;

const formFinitionValidation = {
    metatarsalPad: object({
        id: string(),
    }).required(t('scan.form_informations.validation_errors.metatarsalPad')),
    fullLengthPad: object({
        id: string(),
    }).required(t('scan.form_informations.validation_errors.fullLengthPad')),
    heelPad: object({
        id: string(),
    }).required(t('scan.form_informations.validation_errors.heelPad')),
    isMaunalBend: boolean().required(t('global.form_errors.required')),
};

const formInformationValidation = object({
    manufacture: object({
        id: string(),
    }).required(t('scan.form_informations.validation_errors.manufacture')),
    material: object({
        id: string(),
    }).required(t('scan.form_informations.validation_errors.material')),
    pattern: object({
        id: string(),
    }).required(t('scan.form_informations.validation_errors.pattern')),
    shoeSize: object({
        id: string(),
    }).required(t('scan.form_informations.validation_errors.shoeSize')),
    cutLength: object({
        id: string(),
    }).required(t('scan.form_informations.validation_errors.cutLength')),
    covering: object({
        id: string(),
    }).required(t('scan.form_informations.validation_errors.covering')),
}).when('$manufacture', ([element], schema, context) => {
    if (context.value.manufacture && context.value.manufacture.id === ManufactureTypeEnum.Milling) {
        return schema.shape(formFinitionValidation);
    }
    return schema.shape({});
});

export default formInformationValidation;
