import useAuthStore from '@/domains/authentication/store/auth.store';
import apiSettings from '@/domains/settings/api/settings.api';
import DeviceLightSensibilityEnum from '@/domains/scan/typescript/enums/DeviceLightSensibilityEnum';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import type SettingsInterface from '@/domains/settings/typescript/interfaces/SettingsInterface';
import type PodonexConfigurationInterface from '@/domains/settings/typescript/interfaces/PodonexConfigurationInterface';

export default defineStore('settings', () => {
    const authStore = useAuthStore();

    const defaultManufacturePreferences: SettingsInterface = {
        '@id': '',
        id: '',
        userId: '',
        manufacture: null,
        padProvided: false,
        viewSynchronized: false,
        comment: '',
        isSanded: false,
        isGlued: false,
    };

    const manufacturePreferencesIri = ref<string>();
    const manufacturePreferences = ref<SettingsInterface>(defaultManufacturePreferences);

    const fetchManufacturePreferences = async (): Promise<void> => {
        if (manufacturePreferencesIri.value !== undefined) {
            manufacturePreferences.value = await apiSettings.getManufactureSettingsFromIri(
                manufacturePreferencesIri.value,
            );
        } else {
            manufacturePreferences.value.userId = `/users/${authStore.userId}` ?? '';
        }
    };

    const defaultPodonexConfiguration: PodonexConfigurationInterface = {
        '@id': '',
        id: '',
        scannerId: '',
        lightSensibility: DeviceLightSensibilityEnum.NORMAL,
        sobelThreshold: 10,
        cleaningSteps: 15,
        brightness: '0',
        contrast: '0',
        userId: '',
    };

    const podonexConfigurationIri = ref<string>();
    const podonexConfiguration = ref<PodonexConfigurationInterface>(defaultPodonexConfiguration);

    const fetchPodonexConfiguration = async (): Promise<void> => {
        if (podonexConfigurationIri.value !== undefined) {
            podonexConfiguration.value = await apiSettings.getPodonexConfigurationFromIri(
                podonexConfigurationIri.value,
            );
        } else {
            podonexConfiguration.value.userId = `/users/${authStore.userId}`;
        }
    };

    function $reset() {
        console.log('reset settings store');
        manufacturePreferencesIri.value = undefined;
        manufacturePreferences.value = defaultManufacturePreferences;
        podonexConfigurationIri.value = undefined;
        podonexConfiguration.value = defaultPodonexConfiguration;
    }

    return {
        manufacturePreferences,
        manufacturePreferencesIri,
        fetchManufacturePreferences,

        podonexConfiguration,
        podonexConfigurationIri,
        defaultPodonexConfiguration,
        fetchPodonexConfiguration,

        $reset,
    };
});
