import Gizmo from './Gizmo';
import ScanController from '@/3d-app/scan/ScanController';
import { Mesh, MeshBasicMaterial, PlaneGeometry, TextureLoader, Vector3 } from 'three';
import type ElementMesh from '@/3d-app/scan/elementPlacement/meshes/ElementMesh';

/**
 * Gizmo that shows arrows at element corners, and is used to set rotation
 */
class RotationGizmo extends Gizmo {
    private _texturedSquareTopRight: Mesh;
    private _texturedSquareTopLeft: Mesh;
    private _texturedSquareBottomRight: Mesh;
    private _texturedSquareBottomLeft: Mesh;
    private _highlightMaterial: MeshBasicMaterial;

    public constructor(transformFunction: (pos: Vector3) => void) {
        super(transformFunction);

        // Load texture
        const texture = new TextureLoader().load('/images/rotation-gizmo.png');

        // Mesh build
        this._highlightMaterial = new MeshBasicMaterial({
            color: 0xaaffaa,
            transparent: true,
            opacity: 0.7,
            depthWrite: false,
            depthTest: false,
            map: texture,
        });

        this._texturedSquareTopRight = new Mesh(new PlaneGeometry(1.5, 1.5), this._highlightMaterial);
        this._texturedSquareTopLeft = this._texturedSquareTopRight.clone().rotateZ(Math.PI / 2);
        this._texturedSquareBottomRight = this._texturedSquareTopRight.clone().rotateZ(-Math.PI / 2);
        this._texturedSquareBottomLeft = this._texturedSquareTopRight.clone().rotateZ(Math.PI);

        this._texturedSquareTopRight.layers.enable(2);
        this._texturedSquareTopLeft.layers.enable(2);
        this._texturedSquareBottomRight.layers.enable(2);
        this._texturedSquareBottomLeft.layers.enable(2);

        this.autoRescaleElement(this._texturedSquareTopRight);
        this.autoRescaleElement(this._texturedSquareTopLeft);
        this.autoRescaleElement(this._texturedSquareBottomRight);
        this.autoRescaleElement(this._texturedSquareBottomLeft);

        this.add(this._texturedSquareTopRight);
        this.add(this._texturedSquareTopLeft);
        this.add(this._texturedSquareBottomRight);
        this.add(this._texturedSquareBottomLeft);
    }

    public setHighlighted(highlighted: boolean): void {
        super.setHighlighted(highlighted);
        this._highlightMaterial.opacity = highlighted ? 1.0 : 0.7;
    }

    public update(attachedMesh: ElementMesh): void {
        // Update position, rotation and scale (compensation for base mesh deform)
        const bbSize = new Vector3(0, 0, 0);
        const offset = (ScanController.footSize / 10) * ScanController.SCALE_TO_M_FACTOR; // I don't fancy adapt rect position, so that will help.
        const offsetX = offset / attachedMesh.scale.x;
        const offsetY = offset / attachedMesh.scale.y;

        attachedMesh.boundingBox.getSize(bbSize);

        const zPos = 0.1 * ScanController.SCALE_TO_M_FACTOR + bbSize.z / 2; // Z position to set order of gizmos

        const finalScale = new Vector3(1, 1, 1).divide(attachedMesh.scale);
        const revertedFinalScale = new Vector3(finalScale.y, finalScale.x, finalScale.z);
        this._texturedSquareTopRight.scale.copy(finalScale);
        this._texturedSquareTopRight.position.set(bbSize.x / 2 + offsetX, bbSize.y / 2 + offsetY, zPos);
        this._texturedSquareTopLeft.scale.copy(revertedFinalScale);
        this._texturedSquareTopLeft.position.set(-bbSize.x / 2 - offsetX, bbSize.y / 2 + offsetY, zPos);
        this._texturedSquareBottomRight.scale.copy(revertedFinalScale);
        this._texturedSquareBottomRight.position.set(bbSize.x / 2 + offsetX, -bbSize.y / 2 - offsetY, zPos);
        this._texturedSquareBottomLeft.scale.copy(finalScale);
        this._texturedSquareBottomLeft.position.set(-bbSize.x / 2 - offsetX, -bbSize.y / 2 - offsetY, zPos);
    }
}

export default RotationGizmo;
