import transformProjectionToSurfaceType from '@/domains/scan/services/transformProjectionToSurfaceType';
import type SurfaceTypeEnum from '@/domains/scan/typescript/enums/SurfaceTypeEnum';
import type SoleConfigurationElementInterface from '@/domains/scan/typescript/interfaces/SoleConfigurationElementInterface';
import type SoleConfigurationElementDTOInterface from '@/domains/scan/typescript/interfaces/SoleConfigurationElementDTOInterface';
import type SoleConfigurationElementTagDTOInterface from '@/domains/scan/typescript/interfaces/SoleConfigurationElementTagDTOInterface';
import type SoleConfigurationElementTagInterface from '@/domains/scan/typescript/interfaces/SoleConfigurationElementTagInterface';
import type SoleConfigurationElementCategoryInterface from '@/domains/scan/typescript/interfaces/SoleConfigurationElementCategoryInterface';

function transformDTOtoSoleElement(
    dtoSoleElement: SoleConfigurationElementDTOInterface,
): SoleConfigurationElementInterface {
    let tags: SoleConfigurationElementTagDTOInterface[] = [];
    if (Array.isArray(dtoSoleElement.tags)) {
        tags = dtoSoleElement.tags.map(
            (tag: SoleConfigurationElementTagDTOInterface) =>
                ({
                    id: tag.id,
                    name: tag.name,
                }) as SoleConfigurationElementTagInterface,
        );
    }
    const category = {
        id: dtoSoleElement.category.id,
        name: dtoSoleElement.category.name,
        code: dtoSoleElement.category.code,
    } as SoleConfigurationElementCategoryInterface;

    const defaultProjection = transformProjectionToSurfaceType(dtoSoleElement.defaultProjection);

    let projectionAvailable: SurfaceTypeEnum[] = [];
    if (Array.isArray(dtoSoleElement.projectionAvailable)) {
        projectionAvailable = dtoSoleElement.projectionAvailable.map((projection: number) =>
            transformProjectionToSurfaceType(projection),
        );
    }

    return {
        ...dtoSoleElement,
        tags,
        category,
        defaultProjection,
        projectionAvailable,
    } as SoleConfigurationElementInterface;
}

export default transformDTOtoSoleElement;
