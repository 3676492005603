import { Material, Mesh, type Group, type Object3D, Vector3, Camera, Box3, PerspectiveCamera, MathUtils } from 'three';
import type { ContainerSize } from './world/IWorld';

const MeshUtils = {
    /**
     * Remove an object properly, with associated materials and geometry,
     * recursively for the childs.
     * @param object The object to dispose
     */
    disposeObject(object: Object3D | Mesh | Group): void {
        if (object.children.length > 0) {
            for (let x = object.children.length - 1; x >= 0; x--) {
                MeshUtils.disposeObject(object.children[x]);
            }
        }
        if (object instanceof Mesh) {
            object.geometry.dispose();
            if (object.material instanceof Material) {
                object.material.dispose();
            } else if (Array.isArray(object.material)) {
                object.material.forEach((material) => {
                    material.dispose();
                });
            }
        }
        if (object.parent) {
            object.parent.remove(object);
        }
    },

    /**
     * Transform mouse coordiante to 3D coordinate, intersection with given plane
     * @param mouseX x coords of the mouse on screen
     * @param mouseY y coords of the mouse on screen
     * @param containerSize size of the container (canvas)
     * @param camera current camera
     * @param planeNormal target plane normal ((0, 0, 1) by default)
     * @param planeOffset target plane offset (near/far)
     * @returns resulting 3D position
     */
    mouseToWorld(
        mouseX: number,
        mouseY: number,
        containerSize: ContainerSize,
        camera: Camera,
        planeNormal: Vector3 = new Vector3(0, 0, 1),
        planeOffset: number = 0,
    ): Vector3 {
        const pos = new Vector3();

        // Unproject the mouse positions to world coordinates using the modified plane
        const near = new Vector3((mouseX / containerSize.width) * 2 - 1, -(mouseY / containerSize.height) * 2 + 1, -1);
        const far = new Vector3(near.x, near.y, 1);
        near.unproject(camera);
        far.unproject(camera);

        const direction = far.sub(near).normalize();

        // Invert plane normal when facing the other side
        if (near.dot(planeNormal) < 0) {
            planeNormal.multiplyScalar(-1);
        }

        // Prevents infinite distance
        const distance = (planeOffset - near.dot(planeNormal)) / Math.min(-0.0001, direction.dot(planeNormal));

        pos.copy(camera.position).add(direction.multiplyScalar(distance));

        return pos;
    },

    /**
     * Fits the camera's view to the selection of objects while maintaining the camera's orientation.
     * @param {PerspectiveCamera} camera the camera to adjust.
     * @param {Vector3} target position of camera's target.
     * @param {Array<Object3D>} selection the array of objects to fit within the camera view.
     * @param {number} [fitOffset=1] the offset factor to control the spacing around the objects.
     * @returns {Vector3} the new position for the camera.
     */
    fitCameraToSelection(
        camera: PerspectiveCamera,
        target: Vector3,
        selection: Array<Object3D>,
        fitOffset: number = 1,
    ): Vector3 {
        // Create a bounding box to enclose all objects in the selection
        const box = new Box3();
        for (let i = 0; i < selection.length; i += 1) {
            box.expandByObject(selection[i]);
        }

        // Calculate the size, center, and maximum dimension of the bounding box
        const size = new Vector3();
        box.getSize(size);
        const maxSize = Math.max(size.x, size.y, size.z);

        // Calculate the distance to fit the bounding box within the camera's view
        const fitHeightDistance = maxSize / (2 * Math.atan((Math.PI * camera.fov) / 360));
        const fitWidthDistance = fitHeightDistance / camera.aspect;
        const distance = fitOffset + Math.max(fitHeightDistance, fitWidthDistance);

        // Calculate the new camera position based on the controls' target and distance
        const direction = target.clone().sub(camera.position).normalize().multiplyScalar(distance);
        return target.clone().sub(direction);
    },

    /**
     * Get spherical coordinates from cartesian coordinates
     * @param x x coord of cartesian
     * @param y y coord of cartesian
     * @param z z coord of cartesian
     * @returns the spherical coordinates
     */
    sphericalFromCartesian(
        x: number,
        y: number,
        z: number,
    ): {
        radius: number;
        phi: number;
        theta: number;
    } {
        const radius = Math.sqrt(x * x + y * y + z * z);
        let theta = 0;
        let phi = 0;
        if (radius !== 0) {
            theta = Math.atan2(x, z);
            phi = Math.acos(MathUtils.clamp(y / radius, -1, 1));
        }
        return {
            radius,
            phi,
            theta,
        };
    },

    /**
     * Get cartesian coordinates from spherical coordinates
     * @param radius radius of spherical
     * @param phi phi of spherical (rad)
     * @param theta theta of spherical (rad)
     * @returns cartesian coordinates as a Vector3
     */
    cartesianFromSpherical(radius: number, phi: number, theta: number): Vector3 {
        const sinPhiRadius = Math.sin(phi) * radius;
        return new Vector3(sinPhiRadius * Math.sin(theta), Math.cos(phi) * radius, sinPhiRadius * Math.cos(theta));
    },
};
export default MeshUtils;
