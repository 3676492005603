import transformDTOtoPatient from '@/domains/patient/transformers/transformDTOtoPatients';
import HydraAPI from '@/domains/common/services/hydraApi';
import type PatientInterface from '@/domains/patient/typescript/PatientInterface';
import type PatientDTOCollectionInterface from '@/domains/patient/typescript/PatientDTOCollectionInterface';
import type PatientDTOInterface from '@/domains/patient/typescript/PatientDTOInterface';
import type PaginatedEntitiesInterface from '@/domains/common/typescript/PaginatedEntitiesInterface';
import type PatientFormInterface from '@/domains/patient/typescript/PatientFormInterface';

const getPatients = async (
    urlSearchParams?: URLSearchParams,
): Promise<PaginatedEntitiesInterface<PatientInterface>> => {
    const url = !urlSearchParams ? '/patients' : `/patients?${urlSearchParams.toString()}`;
    const data = await HydraAPI.getJson<PatientDTOCollectionInterface>(url);

    return {
        entities: data['hydra:member'].map((patientDto) => transformDTOtoPatient(patientDto)),
        total: data['hydra:totalItems'],
    };
};

const getPatient = async (id: PatientDTOInterface['id']): Promise<PatientInterface> => {
    const url = `/patients/${id}`;
    const data = await HydraAPI.getJson<PatientDTOInterface>(url);

    return transformDTOtoPatient(data);
};

const createPatient = async (patientForm: PatientFormInterface, officeUser: string): Promise<PatientInterface> => {
    const url = '/patients';
    const body = JSON.stringify({ ...patientForm, officeUser });
    const data = await HydraAPI.postJson<PatientDTOInterface>(url, { body });

    return transformDTOtoPatient(data);
};

const updatePatient = async (
    patientForm: Omit<PatientFormInterface, 'officeUser'>,
    id: PatientInterface['id'],
): Promise<PatientInterface> => {
    const url = `/patients/${id}`;
    const body = JSON.stringify(patientForm);
    const data = await HydraAPI.putJson<PatientDTOInterface>(url, { body });

    return transformDTOtoPatient(data);
};

export default { getPatients, getPatient, createPatient, updatePatient };
